import { Group } from '@visx/group';
import { AreaClosed, LinePath } from '@visx/shape';
import { curveBasis } from '@visx/curve';
import { withParentSize } from '@visx/responsive';
import { scaleLinear, scaleUtc } from '@visx/scale';
import time from '@utils/time';
import { motion } from 'framer-motion';
import { LinearGradient } from '@visx/gradient';
import { WithParentSizeProps } from '@visx/responsive/lib/enhancers/withParentSize';
import { useRef } from 'react';

export type Data = {
  x: string;
  y: number;
}[];

type ChartData = {
  x: Date;
  y: number;
}[];

interface SimpleLineProps extends WithParentSizeProps {
  data: Data;
  color?: string;
  parentWidth?: number;
  parentHeight?: number;
}

function transformData(data: Data): ChartData {
  return data.map((d) => ({
    x: time(d.x).toDate(),
    y: d.y,
  }));
}

export const SimpleLine = withParentSize<SimpleLineProps>(function SimpleLine({
  data,
  color = 'var(--primary)',
  parentWidth = 0,
  parentHeight = 0,
}: SimpleLineProps) {
  const yMax = parentHeight;
  const hasDataOnMount = useRef(data.length > 0);
  const lineData = data.length === 0 ? emptyData() : transformData(data);

  const xScale = scaleUtc({
    range: [1, parentWidth - 1],
    domain: [lineData[0].x, lineData[lineData.length - 1].x],
  });

  const yMin = Math.min(...lineData.map((d) => d.y));

  const yScale = scaleLinear({
    range: [yMax - 2, 2],
    domain: [0, Math.max(...lineData.map((d) => d.y), 1)],
  });

  return (
    <svg width={parentWidth} height={parentHeight}>
      <Group>
        <LinePath
          key={data?.length ? 'loaded' : 'empty'}
          data={lineData}
          curve={curveBasis}
          x={(d) => xScale(d.x)}
          y={(d) => yScale(d.y)}
        >
          {(line) => (
            <motion.path
              fill="transparent"
              stroke={color}
              strokeWidth={1.5}
              initial={{
                d: hasDataOnMount.current
                  ? line.path(lineData) || undefined
                  : line.path(lineData.map((d) => ({ ...d, y: yMin }))) ||
                    undefined,
              }}
              animate={{
                d: line.path(lineData) || undefined,
              }}
              transition={{
                duration: 0.2,
                ease: 'easeInOut',
              }}
            />
          )}
        </LinePath>
        <AreaClosed
          key={data?.length ? 'areaLoaded' : 'areaEmpty'}
          data={lineData}
          yScale={yScale}
          x={(d) => xScale(d.x)}
          y={(d) => yScale(d.y)}
          curve={curveBasis}
        >
          {(area) => (
            <motion.path
              fill="url(#gradient)"
              stroke="transparent"
              initial={{
                d: hasDataOnMount.current
                  ? area.path(lineData) || undefined
                  : area.path(lineData.map((d) => ({ ...d, y: yMin }))) ||
                    undefined,
              }}
              animate={{
                d: area.path(lineData) || undefined,
              }}
              transition={{
                duration: 0.2,
                ease: 'easeInOut',
              }}
            />
          )}
        </AreaClosed>
        <LinearGradient
          from={color}
          fromOpacity={0.25}
          to="var(--primary-transparent)"
          toOffset="90%"
          id="gradient"
        />
      </Group>
    </svg>
  );
});

function emptyData(): ChartData {
  return [
    {
      x: time().subtract(1, 'day').toDate(),
      y: 0,
    },
    {
      x: time().toDate(),
      y: 0,
    },
  ];
}
