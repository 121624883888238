import classNames from 'classnames';
import { motion } from 'framer-motion';
import css from './Nodes.module.css';

export default function DiagramRequest({
  className,
  children,
  ease,
  duration = 3,
  delay = 0,
  repeatDelay = 0,
  encrypted = false,
}) {
  const classnames = classNames(
    css.request,
    encrypted && css.encryptedRequest,
    className
  );

  return (
    <motion.div
      className={classnames}
      initial={{ offsetDistance: '0%' }}
      animate={{ offsetDistance: '100%' }}
      transition={{ duration, delay, repeatDelay, ease, repeat: Infinity }}
      data-encrypted={true}
    >
      {children}
    </motion.div>
  );
}
