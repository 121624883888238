import React from 'react';
import styles from './MixedList.module.css';
import cn from 'classnames';

export default function MixedList({ children, className }) {
  return <div className={cn(styles.container, className)}>{children}</div>;
}

const Row = ({
  children,
  showActionOnHover = false,
  isHeader = false,
  isWarning = false,
  onClick,
}) => (
  <div
    onClick={onClick}
    data-header={isHeader}
    className={cn(
      styles.row,
      showActionOnHover && styles.showActionOnHover,
      isWarning && styles.rowWarning,
      onClick && styles.rowClickable
    )}
  >
    {children}
  </div>
);

const Data = ({ children }) => <div className="flex-fill">{children}</div>;

const Action = ({ children }) => (
  <div className={styles.action}>{children}</div>
);

MixedList.Row = Row;
MixedList.Data = Data;
MixedList.Action = Action;
