import { motion } from 'framer-motion';
import { useContext, useLayoutEffect, useState } from 'react';
import css from './Nodes.module.css';
import { DiagramContext } from '.';

function useConnectionPath(nodes, anchors, strength) {
  const [path, setPath] = useState(null);
  const { id, direction } = useContext(DiagramContext);

  useLayoutEffect(() => {
    const viewport = document.getElementById(`nodes-${id}`);
    const positions = nodes.map((node) => {
      const el = document.getElementById(`node-${node}`);
      return getPositionForNode(viewport, el, anchors?.[node]);
    });

    setPath(generatePath(positions, strength, direction));
  }, [id]);

  return path;
}

function getPositionForNode(viewport, node) {
  const viewportRect = viewport.getBoundingClientRect();
  const rect = node.getBoundingClientRect();

  return {
    x: rect.x + rect.width / 2 - viewportRect.x,
    y: rect.y + rect.height / 2 - viewportRect.y,
    width: rect.width,
    height: rect.height,
  };
}

function generatePath(points, strength = 40, direction = 'horizontal') {
  if (points.length < 2) return null;

  const path = [];
  for (let i = 0; i < points.length - 1; i++) {
    const from = points[i];
    const to = points[i + 1];
    let curves = [];

    if (i === 0) path.push(`M ${from.x} ${from.y}`);

    if (direction === 'vertical') {
      const curveStart = { x: from.x, y: from.y + from.height / 2 };
      const curveEnd = { x: to.x, y: to.y - to.height / 2 };
      curves = [
        { x: curveStart.x, y: curveStart.y + strength },
        { x: curveEnd.x, y: curveEnd.y - strength },
        { x: curveEnd.x, y: curveEnd.y },
      ];
    } else {
      const curveStart = { x: from.x + from.width / 2, y: from.y };
      const curveEnd = { x: to.x - to.width / 2, y: to.y };
      curves = [
        { x: curveStart.x + strength, y: curveStart.y },
        { x: curveEnd.x - strength, y: curveEnd.y },
        { x: curveEnd.x, y: curveEnd.y },
      ];
    }

    path.push(
      `L ${from.x} ${from.y} C ${curves[0].x} ${curves[0].y}, ${curves[1].x} ${curves[1].y}, ${curves[2].x} ${curves[2].y} L ${to.x} ${to.y}`
    );
  }

  return path.join(' ');
}

export default function DiagramConnection({
  nodes,
  children,
  anchors,
  strength,
  ...props
}) {
  const { resizing } = useContext(DiagramContext);
  if (resizing) return null;

  const path = useConnectionPath(nodes, anchors, strength);
  if (!path) return null;

  return (
    <div className={css.connection} style={{ '--path': `"${path}"` }}>
      <svg>
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          d={path}
          stroke="var(--grey-60)"
          strokeOpacity={0.5}
          fill="none"
          strokeWidth={1.2}
          strokeDasharray="1 4"
          {...props}
        />
      </svg>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {children}
      </motion.div>
    </div>
  );
}
