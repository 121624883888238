import * as Sentry from '@sentry/react';

function handleRawApiError(func) {
  return async (...opts) => {
    try {
      const result = await func(...opts);
      return result;
    } catch (err) {
      const status = err?.response?.status;

      if (status !== 401 && status !== 403) {
        Sentry.withScope((scope) => {
          const requestUuid = err?.response?.headers?.['x-request-id'];

          if (requestUuid) {
            scope.setTag('request_id', requestUuid);
          }

          scope.setTag('reported_from', 'raw-api-error');
          Sentry.captureException(err);
        });
      }

      throw err;
    }
  };
}

export default handleRawApiError;
