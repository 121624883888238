import {
  workflow as workflowResource,
  workflows as workflowsResource,
} from '@utils/resources/workflow';
import { hubspot as hubspotResource } from '@utils/resources/hubspot';
import { api, handleResponseOptions } from '@services/apiService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const workflowKeys = {
  all: (teamUuid: string, appUuid: string) => [
    'teams',
    teamUuid,
    'apps',
    appUuid,
    'workflows',
  ],
  byId: (teamUuid: string, appUuid: string, workflowUuid: string) => [
    ...workflowKeys.all(teamUuid, appUuid),
    workflowUuid,
  ],
};

const workflowQuery = (
  teamUuid: string,
  appUuid: string,
  workflowUuid: string
) => ({
  queryKey: workflowKeys.byId(teamUuid, appUuid, workflowUuid),
  queryFn: async () =>
    await api
      .get(
        workflowResource(teamUuid, appUuid, workflowUuid),
        handleResponseOptions('workflowQuery')
      )
      .json(),
});

export const workflowsQuery = (teamUuid: string, appUuid: string) => ({
  queryKey: workflowKeys.all(teamUuid, appUuid),
  queryFn: async () =>
    await api
      .get(
        workflowsResource(teamUuid, appUuid),
        handleResponseOptions('workflowsQuery')
      )
      .json(),
});

export const useWorkflowsQuery = () => {
  const { teamUuid, appUuid } = useParams() as {
    teamUuid: string;
    appUuid: string;
  };

  return useQuery(workflowsQuery(teamUuid, appUuid));
};

export const useWorkflowQuery = (workflowUuid: string) => {
  const { teamUuid, appUuid } = useParams() as {
    teamUuid: string;
    appUuid: string;
  };

  return useQuery(workflowQuery(teamUuid, appUuid, workflowUuid));
};

export type CreateWorkflow = {
  functionUuid: string;
  integrationUuid: string;
  name: string;
};

export type UpdateWorkflow = {
  functionUuid: string;
  integrationUuid: string;
  name: string;
  resourceUuid: string;
  resourceType: string;
  metadata: object;
};

export const useCreateWorkflowMutation = () => {
  const { teamUuid, appUuid } = useParams<{
    teamUuid: string;
    appUuid: string;
  }>();

  const queryClient = useQueryClient();

  const createWorkflow = async (createWorkflowData: CreateWorkflow) => {
    return api.post(workflowsResource(teamUuid, appUuid), {
      ...handleResponseOptions('createWorkflowMutation'),
      json: createWorkflowData,
    });
  };

  return useMutation({
    mutationFn: createWorkflow,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: workflowKeys.all(teamUuid, appUuid),
      });
    },
  });
};

export const useUpdateWorkflowMutation = () => {
  const { teamUuid, appUuid } = useParams<{
    teamUuid: string;
    appUuid: string;
  }>();

  const queryClient = useQueryClient();

  const updateWorkflow = async (
    updateWorkflowData: UpdateWorkflow,
    workflowUuid: string
  ) => {
    return api.post(workflowResource(teamUuid, appUuid, workflowUuid), {
      ...handleResponseOptions('updateWorkflowMutation'),
      json: updateWorkflowData,
    });
  };

  return useMutation({
    mutationFn: ({ workflowUuid, data }) => updateWorkflow(data, workflowUuid),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: workflowKeys.all(teamUuid, appUuid),
      });
    },
  });
};

export const useDeleteAccountMutation = () => {
  const { teamUuid, appUuid } = useParams<{
    teamUuid: string;
    appUuid: string;
  }>();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (workflowUuid) =>
      api.delete(
        workflowResource(teamUuid, appUuid, workflowUuid),
        handleResponseOptions('deleteAccountMutation')
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: workflowKeys.all(teamUuid, appUuid),
      });
    },
  });
};

const hubspotMutation = (teamUuid: string, appUuid: string) => ({
  mutationFn: async (apiKey: string) => {
    return await api
      .post(hubspotResource(teamUuid, appUuid), {
        ...handleResponseOptions('hubspotMutation'),
        json: {
          apiKey,
        },
      })
      .json();
  },
});

export const useGetHubspotTeamsMutation = () => {
  const { teamUuid, appUuid } = useParams() as {
    teamUuid: string;
    appUuid: string;
  };

  return useMutation(hubspotMutation(teamUuid, appUuid));
};
