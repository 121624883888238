import { ChevronRight } from 'lucide-react';
import { Link, LinkProps } from 'react-router-dom';
import { MouseEvent } from 'react';

import styles from './SecondaryCard.module.css';

interface SecondaryCardProps {
  title: string;
  icon: React.ReactNode;
  to?: LinkProps['to'];
  href?: string;
  grow?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export default function SecondaryCard({
  title,
  icon,
  href,
  to,
  onClick,
  grow,
}: SecondaryCardProps) {
  const Container = href ? 'a' : to ? Link : 'div';

  return (
    <Container
      className={styles.container}
      href={href}
      to={to!}
      target="_blank"
      onClick={onClick}
      data-grow={grow}
    >
      <div className={styles.iconContainer}>{icon}</div>
      <p>{title}</p>
      <ChevronRight width={15} className={styles.chevron} />
    </Container>
  );
}
