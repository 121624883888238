/**
 *
 * @returns {string}
 */
export const teams = () => 'teams';

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const team = (teamUuid) => `${teams()}/${teamUuid}`;

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const teamUsers = (teamUuid) => `${team(teamUuid)}/users`;

/**
 *
 * @param {string} teamUuid
 * @param {string} userId
 * @returns {string}
 */
export const teamUser = (teamUuid, userId) =>
  `${teamUsers(teamUuid)}/${userId}`;

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const teamToggleMfa = (teamUuid) => `${team(teamUuid)}/toggle-mfa`;

export const teamIntegrations = (teamUuid) =>
  `${team(teamUuid)}/thirdPartyIntegrations`;

export const teamIntegration = (teamUuid, integrationUuid) =>
  `${teamIntegrations(teamUuid)}/${integrationUuid}`;

export const datadogIntegrations = (teamUuid) => `${team(teamUuid)}/datadog`;
export const datadogIntegration = (teamUuid, integrationUuid) =>
  `${datadogIntegrations(teamUuid)}/${integrationUuid}`;

export const teamSecurity = (teamUuid) => `${team(teamUuid)}/security`;

export const samlConnections = (teamUuid) => `${teamSecurity(teamUuid)}/saml`;

export const samlConnection = (teamUuid, connectionUuid) =>
  `${samlConnections(teamUuid)}/${connectionUuid}`;

export const samlConnectionMetadata = (teamUuid, connectionUuid) =>
  `${samlConnection(teamUuid, connectionUuid)}/metadata`;
