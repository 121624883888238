import { api, handleResponseOptions } from '@services/apiService';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  userChangePassword,
  users as usersResource,
} from '@utils/resources/users';
import { useParams } from 'react-router-dom';
import { useTeamQuery } from './team';

// Scope Types

/**
 * @type {'query/User'}
 */
export const USER_QUERY_KEY = ['user'];

// Query Definitions

export const userQuery = {
  staleTime: 60 * 1000,
  queryKey: USER_QUERY_KEY,
  queryFn: async () => {
    try {
      const response = await api.get(
        usersResource(),
        handleResponseOptions('userQuery')
      );

      return {
        _auth: true,
        ...(await response.json()),
      };
    } catch (error) {
      throw error;
    }
  },
};

// Query Selectors
export const isLoggedInSelector = (data) => data._auth === true;
export const userSelector = (data) => (data._auth === true ? data.user : null);
export const userTeamsSelector = (data) => userSelector(data)?.teams ?? null;
export const userInvitesSelector = (data) =>
  userSelector(data)?.invites ?? null;

// Query Hooks
export const useUserQuery = (params) =>
  useQuery({
    ...userQuery,
    ...params,
    select: params?.select ?? userSelector,
    enabled: params?.enabled ?? true,
  });

export const useRequestPasswordChange = (opts = {}) => {
  const { data: user } = useUserQuery();

  return useMutation({
    mutationFn: async () => {
      return fetch(
        `https://${import.meta.env.VITE_AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
            email: user.email,
            connection: 'Username-Password-Authentication',
          }),
        }
      );
    },
    ...opts,
  });
};

export function useCurrentTeam() {
  const { teamUuid } = useParams();
  const { data, isInitialLoading } = useUserQuery();

  const fromUserData = (data?.teams || []).find((t) => t.uuid === teamUuid);
  const isSuperUser = data?.roleName === 'superuser';
  const shouldUseTeamQuery = !fromUserData && isSuperUser && !!teamUuid;

  const teamQuery = useTeamQuery(teamUuid, {
    enabled: shouldUseTeamQuery,
  });

  if (shouldUseTeamQuery) {
    return {
      loading: teamQuery.isInitialLoading,
      team: teamQuery.data,
    };
  }

  return {
    loading: isInitialLoading,
    team: fromUserData,
  };
}

export function useCurrentApp() {
  const { appUuid } = useParams();
  const { loading, team } = useCurrentTeam();

  return {
    loading,
    app: (team?.apps || []).find((app) => app.uuid === appUuid),
  };
}

export function teamHasBillingError(team) {
  return team?.tier === 'pro' && !team?.stripeCustomerId;
}
