import { ChartCard } from '@common/ChartCard/ChartCard';
import format from '@utils/format';
import time from '@utils/time';
import { Tag } from '@common/Tag/Tag';
import styles from './EnclaveCard.module.css';
import { useResourceVolumne } from '@common/ChartCard/queries';

const desiredReplicasString = (desiredReplicas) => {
  if (desiredReplicas === 0) {
    return 'No replicas';
  } else if (desiredReplicas === 1) {
    return '1 replica';
  } else {
    return `${desiredReplicas} replicas`;
  }
};

function TimeRemaining({ enclave }) {
  if (!enclave?.timeToBeDeleted || !enclave?.isTimeBound) {
    return null;
  }

  const expirationDate = time(enclave?.timeToBeDeleted);
  const hasExpired = expirationDate && time().isAfter(expirationDate);

  if (!expirationDate) return null;

  return (
    <Tag data-size="xs" className="ml-8">
      {hasExpired ? (
        <>Expired</>
      ) : (
        <>{expirationDate.fromNow().replace('in ', '')} remaining</>
      )}
    </Tag>
  );
}

function InstancesRunning({ enclave }) {
  if (!enclave?.enclaveScalingConfig || enclave.state !== 'active') return null;

  const { desiredReplicas } = enclave?.enclaveScalingConfig;

  return (
    <Tag data-size="xs" className="ml-8">
      {desiredReplicasString(desiredReplicas)}
    </Tag>
  );
}

const isDeleted = (state) => state === 'deleted';

const enclaveState = (enclave) => {
  if (isDeleted(enclave.state)) {
    return `Deleted ${time(enclave.updatedAt).fromNow()}`;
  }

  const createdTime = `${format.capitalizeFirstLetter(
    time(enclave.createdAt).fromNow()
  )}`;

  if (enclave.state === 'pending' && !enclave.enclaveScalingConfig) {
    return `${createdTime} • not deployed`;
  }

  return `${createdTime} • ${enclave.state}`;
};

export const EnclaveCard = ({ enclave }) => {
  const { data } = useResourceVolumne(enclave.uuid);

  return (
    <div className={isDeleted(enclave?.state) ? styles.deleted : null}>
      <ChartCard
        name={enclave.name}
        data={data}
        text={enclaveState(enclave)}
        to={!isDeleted(enclave?.state) && `${enclave.uuid}/general`}
        status={
          <>
            <InstancesRunning enclave={enclave} />
            <TimeRemaining enclave={enclave} />
          </>
        }
      />
    </div>
  );
};
