import styles from './YouTubeEmbed.module.css';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

interface YouTubeEmbedProps {
  embedId: string;
  aspectRatio?: number;
}

export default function YouTubeEmbed({
  embedId,
  aspectRatio = 16 / 9,
}: YouTubeEmbedProps) {
  return (
    <AspectRatio.Root ratio={aspectRatio} className={styles.container}>
      <iframe
        className={styles.iframe}
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </AspectRatio.Root>
  );
}
