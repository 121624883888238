import { AnimatePresence, motion } from 'framer-motion';

import transitions from '@utils/transitions';
import { IconCheck } from '@common/Icons/Icons';

import styles from './SelectCard.module.css';

export const SelectCard = ({
  name = '',
  description,
  selected = false,
  anySelected = false,
  accentColor,
  Logo = null,
  ...props
}) => (
  <motion.div
    className={styles.clickableCard}
    variants={{
      initial: {
        color: accentColor || 'var(--primary-60)',
        y: 0,
        boxShadow: '0px 6px 32px rgba(0,0,0,0)',
        opacity: 1,
      },
      hover: {
        color: accentColor || 'var(--primary-100)',
        y: -2,
        boxShadow: '0px 6px 32px rgba(0,0,0,.08)',
        opacity: 0.8,
      },
      collapsed: {
        color: accentColor || 'var(--primary-60)',
        opacity: 1,
      },
      selected: {
        color: accentColor || 'var(--primary-100)',
        opacity: 1,
      },
    }}
    whileHover={'hover'}
    animate={selected ? 'selected' : 'initial'}
    {...props}
  >
    <motion.div className={styles.check} data-outline />
    <motion.div
      className={styles.check}
      variants={{
        initial: {
          opacity: 0,
        },
        selected: {
          opacity: 1,
        },
      }}
    >
      <IconCheck />
    </motion.div>
    {Logo && <div className={'mb-8'}>{Logo}</div>}
    <motion.h5 className={'m-0'}>{name}</motion.h5>
    <AnimatePresence initial={false}>
      {!anySelected && description && (
        <motion.p
          key={'description'}
          className={'small text-muted m-0'}
          {...transitions.collapsible}
        >
          <span className={'d-inline-block mt-8'}>{description}</span>
        </motion.p>
      )}
    </AnimatePresence>
  </motion.div>
);
