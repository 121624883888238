import { ReactElement } from 'react';

export function extractLabelsFromTabs(children: ReactElement | ReactElement[]) {
  if (!Array.isArray(children)) {
    return [children?.props?.label];
  }

  return children
    .map((child) => {
      return child?.props?.label;
    })
    .filter(Boolean);
}
