import { useState } from 'react';

import { IconClose } from '@common/Icons/Icons';
import LocalStorageService from '@services/LocalStorageService';

import styles from './AnnouncementBanner.module.css';

export default function AnnouncementBanner() {
  const announcementId = 'redaction-beta';
  const { setItem, getItem } = LocalStorageService;
  const [showBanner, setShowBanner] = useState(
    getItem(announcementId) ? false : true
  );

  // Hide banner till next announcement
  return null;

  return (
    <div className={styles.banner}>
      Inbound Relay now supports redaction
      {` -> `}
      <a href="https://www.youtube.com/watch?v=bEYWw_cJ-58" target="_blank">
        Learn more
      </a>
      <button
        className={styles.closeButton}
        onClick={() => {
          setShowBanner(false);
          setItem(announcementId, new Date());
        }}
      >
        <IconClose />
      </button>
    </div>
  );
}
