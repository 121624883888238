function Evervault() {
  return (
    <svg
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 19.7583V26.8307C29.9827 25.5842 29.191 24.4865 28.0294 24.0984L14.3451 19.527C12.3747 18.8687 10.2251 19.07 8.4052 20.0833L1.17026 24.1116C0.464166 24.5047 0.01788 25.2527 9.65023e-08 26.0729L0 19.0005C0.0178821 18.1803 0.464168 17.4323 1.17027 17.0392L5.77255 14.4767C9.27243 12.5281 13.4062 12.1409 17.1955 13.4068L28.0294 17.026C29.191 17.4141 29.9827 18.5118 30 19.7583Z"
        fill="#5229BE"
      />
      <path
        d="M2.43481e-07 25.4161L0 18.3437C0.0172908 19.5902 0.809001 20.6879 1.97064 21.076L12.8045 24.6952C16.5938 25.9611 20.7276 25.5739 24.2275 23.6253L28.8297 21.0628C29.5358 20.6697 29.9821 19.9217 30 19.1014V26.1739C29.9821 26.9941 29.5358 27.7421 28.8297 28.1352L24.2275 30.6976C20.7276 32.6463 16.5938 33.0335 12.8045 31.7676L1.97064 28.1484C0.809001 27.7603 0.0172908 26.6626 2.43481e-07 25.4161Z"
        fill="#6633EE"
      />
      <path
        d="M30 7.08378V14.1562C29.9827 12.9097 29.191 11.8119 28.0294 11.4239L14.3451 6.85246C12.3747 6.19415 10.2251 6.39553 8.4052 7.40884L1.17026 11.4371C0.464166 11.8302 0.01788 12.5782 9.65023e-08 13.3984L0 6.32602C0.0178821 5.50578 0.464168 4.75781 1.17027 4.36469L5.77255 1.80222C9.27243 -0.146427 13.4062 -0.533629 17.1955 0.732274L28.0294 4.3515C29.191 4.73959 29.9827 5.83726 30 7.08378Z"
        fill="#5229BE"
      />
      <path
        d="M2.43481e-07 12.7417L0 5.66925C0.0172908 6.91577 0.809001 8.0135 1.97064 8.40157L12.8045 12.0208C16.5938 13.2867 20.7276 12.8995 24.2275 10.9508L28.8297 8.38837C29.5358 7.99522 29.9821 7.24725 30 6.42701V13.4994C29.9821 14.3197 29.5358 15.0676 28.8297 15.4608L24.2275 18.0232C20.7276 19.9719 16.5938 20.3591 12.8045 19.0932L1.97064 15.4739C0.809001 15.0859 0.0172908 13.9882 2.43481e-07 12.7417Z"
        fill="#6633EE"
      />
    </svg>
  );
}

export default Evervault;
