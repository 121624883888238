import { motion } from 'framer-motion';
import css from './Nodes.module.css';
import Evervault from './Evervault';

export default function DiagramLogo({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      className={css.logo}
    >
      <Evervault />
      {children && (
        <motion.div
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.15, duration: 0.5 }}
          className={css.logoLabel}
        >
          {children}
        </motion.div>
      )}
    </motion.div>
  );
}
