import styles from './SkeletonCards.module.css';

export function SkeletonCards({ instances = 2 }) {
  const mapArray = new Array(instances).fill('');
  return mapArray.map((_, index) => (
    <div
      dat-testid="skeletonCard"
      className={styles.container}
      key={`skeleton-${index}`}
    />
  ));
}
