import React, { useState } from 'react';
import cn from 'classnames';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { AnimatePresence } from 'framer-motion';
import { IconCaretDown, IconClose, IconRemove } from '@common/Icons/Icons';
import { FormHelperText } from '@common/FormHelperText/FormHelperText';

import styles from '@styles/Forms.module.css';

const getPadding = (
  { isMulti, isSearchable, isClearable, hasValue },
  hasIcon,
  size
) => {
  if (hasIcon) {
    if ((isMulti || isSearchable || isClearable) && hasValue) {
      return 'var(--token-08) var(--token-12) var(--token-08) var(--token-32)';
    } else if (size) {
      return 'var(--token-04) var(--token-08) var(--token-04) var(--token-32)';
    } else {
      return 'var(--token-08) var(--token-12) var(--token-08) var(--token-40)';
    }
  } else {
    if (isMulti || isSearchable || isClearable) {
      return 'var(--token-08) var(--token-12)';
    } else if (isMulti || isSearchable || isClearable || size) {
      return 'var(--token-04) var(--token-08)';
    } else {
      return 'var(--input-padding)';
    }
  }
};

const reactSelectStyles = (hasIcon, validator, minWidth, size) => ({
  container: (styles) => ({
    ...styles,
    zIndex: 6,
    position: 'relative',
    width: '100%',
  }),
  control: (styles, props) => ({
    ...styles,
    minWidth,
    minHeight: size ? 28 : 42,
    height: '100%',
    padding: getPadding(props, hasIcon, size),
    color: 'var(--text-body)',
    backgroundColor: props.hasFocus
      ? 'var(--surface-medium)'
      : 'var(--surface)',
    borderColor: props.hasFocus ? 'var(--primary-80)' : 'var(--border-color)',
    boxShadow: props.hasFocus
      ? 'var(--input-focus-shadow)'
      : 'var(--input-shadow)',
    transition:
      'opacity 0.3s, color 0.3s, border 0.3s, box-shadow 0.3s,\n    background 0.3s',
    '&:hover': {
      color: 'var(--text-body)',
      backgroundColor: 'var(--surface)',
      borderColor: 'var(--border-alt)',
      boxShadow: 'var(--input-hover-shadow)',
    },
  }),
  group: () => ({
    margin: 'var(--token-04) var(--token-16) var(--token-12)',
    display: 'inline-flex',
    flexDirection: 'column',
    '&:last-child': {
      marginBottom: 'var(--token-08)',
    },
  }),
  groupHeading: () => ({
    margin: 'var(--token-04)',
    fontFamily: 'var(--font-family-display)',
    fontSize: 'var(--font-size-sm)',
    fontWeight: '500',
    color: 'var(--secondary-40)',
    textTransform: 'uppercase',
  }),
  menu: (styles) => ({
    ...styles,
    padding: 'var(--token-08) 0',
    backgroundColor: 'var(--surface)',
    borderRadius: 'var(--radius-md)',
    boxShadow: '0 var(--token-08) var(--token-64) rgba(0, 0, 0, 0.12)',
    zIndex: 999999,
  }),
  option: (styles) => ({
    ...styles,
    padding: size
      ? 'var(--token-04) var(--token-08)'
      : 'var(--token-08) var(--token-12)',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    margin: 0,
    color: 'var(--text-muted)',
  }),
  valueContainer: (styles, { hasValue }) => ({
    ...styles,
    padding: 0,
    margin: hasValue ? '-5px 0' : 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    maxWidth: 'calc(100% - 5px)',
    marginRight: 0,
  }),
  multiValue: (styles, data) => {
    const validated = validator(data.data.value);
    return {
      ...styles,
      display: 'flex',
      alignItems: 'center',
      margin: 'var(--token-04)',
      padding:
        'var(--token-04) var(--token-04) var(--token-04) var(--token-08);',
      fontSize: 'var(--font-size-xs)',
      lineHeight: 1.166666667,
      color: !validated ? 'var(--red-dark)' : 'var(--text-body)',
      backgroundColor: !validated
        ? 'var(--red-lightest)'
        : 'var(--surface-medium)',
      borderRadius: 'var(--radius-md)',
      border:
        '1px solid ' +
        (!validated ? 'var(--red-light)' : 'var(--border-color)'),
    };
  },
  multiValueLabel: () => ({
    display: 'inline-block',
    marginRight: 'var(--token-04)',
    fontSize: 'var(--font-size-sm)',
  }),
  multiValueRemove: () => ({
    display: 'flex',
    alignSelf: 'center',
    padding: 0,
    color: 'var(--border-alt)',
    cursor: 'pointer',
  }),
});

const ClearIndicator = (props) => (
  <components.ClearIndicator className="m-n8" {...props}>
    <IconClose width={12} height={12} />
  </components.ClearIndicator>
);

const DropdownIndicator = (props) => (
  <components.DropdownIndicator className={'cursor-pointer'} {...props}>
    <IconCaretDown className={'color-border-alt'} />
  </components.DropdownIndicator>
);

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <IconRemove className={'color-border-alt'} />
  </components.MultiValueRemove>
);

export const FormSelect = React.forwardRef(
  (
    {
      Icon = undefined,
      minWidth = 170,
      isInlineLeft = false,
      isInlineRight = false,
      disabled = false,
      label = undefined,
      id = undefined,
      validator = () => true,
      name,
      errors,
      helperText,
      className,
      components,
      value,
      setValue,
      noDropdown,
      noOptionsMessage,
      size,
      ...props
    },
    ref
  ) => {
    const Component = props?.creatable ? CreatableSelect : Select;
    const [inputValue, setInputValue] = useState('');

    // Generic function to handle creates on any custom events - blur or comma
    const handleCreate = () => {
      const trimmedValue = inputValue?.trim() || '';
      const valueExists = value?.find(
        (option) => option?.label === trimmedValue
      );

      if (!trimmedValue || valueExists) {
        return false;
      }
      setValue([
        ...(value || []),
        { label: trimmedValue, value: trimmedValue },
      ]);
      setInputValue('');
      return true;
    };

    // safety check - if creating on a custom event, ensure we have a callback to set the value
    if (props?.creatable) {
      if (!setValue) {
        throw new Error(
          'Error in FormSelect - no setValue callback given to creatable'
        );
      }
    }

    // reset the input when a user tabs or presses enter
    if (props.onChange) {
      const providedOnChange = props.onChange;
      props.onChange = (...args) => {
        providedOnChange(...args);
        setInputValue('');
      };
    }

    props.onInputChange = (currentValue, { action }) => {
      if (action === 'input-change') {
        if (props?.creatable && currentValue.endsWith(',')) {
          const created = handleCreate();
          if (!created) {
            // if the field already exists, allow the comma key
            setInputValue(currentValue);
          }
        } else {
          setInputValue(currentValue);
        }
      } else if (action === 'input-blur' && props?.creatable) {
        handleCreate(currentValue);
      }
    };

    const theme = (theme) => ({
      ...theme,
      borderRadius: isInlineLeft
        ? 'var(--radius-xs) 0 0 var(--radius-xs)'
        : isInlineRight
        ? '0 var(--radius-xs) var(--radius-xs) 0'
        : 'var(--radius-xs)',
      colors: {
        ...theme.colors,
        primary: 'var(--primary-100)',
        primary75: 'var(--primary-60)',
        primary50: 'var(--border-color)',
        primary25: 'var(--surface-medium)',
        neutral20: 'var(--border-color)',
        neutral30: 'var(--border-color)',
        neutral80: 'var(--text-body)',
      },
    });

    const buildComponents = noDropdown
      ? {
          ClearIndicator: null,
          DropdownIndicator: null,
          IndicatorSeparator: null,
          MultiValueRemove,
          Menu: () => <></>,
          ...components,
        }
      : { ClearIndicator, DropdownIndicator, MultiValueRemove, ...components };

    return (
      <div className={cn(styles.group, className)}>
        {label && <label className={styles.label}>{label}</label>}
        <div
          className={cn('position-relative', disabled && 'pointer-events-none')}
          style={{
            marginRight: isInlineLeft ? -1 : 0,
            marginLeft: isInlineRight ? -1 : 0,
          }}
        >
          {Icon && <div className={styles.inputIcon}>{Icon}</div>}
          <Component
            id={id}
            name={name}
            components={buildComponents}
            noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => null}
            styles={reactSelectStyles(Icon, validator, minWidth, size)}
            theme={theme}
            ref={ref}
            inputValue={inputValue}
            value={value}
            {...props}
          />
        </div>
        <AnimatePresence initial={false}>
          {((Boolean(errors) && Boolean(errors[name])) ||
            Boolean(helperText)) && (
            <FormHelperText
              key={`${name}-helper-text`}
              data-error={!!errors && Boolean(errors[name])}
            >
              {!!errors && !!errors[name] ? errors[name]?.message : helperText}
            </FormHelperText>
          )}
        </AnimatePresence>
      </div>
    );
  }
);
