import { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { isEmpty } from 'ramda';

import { useUserQuery } from '@queries/user';
import useBroadcastChannel from '@hooks/useBroadcastChannel';
import useModal from '@hooks/useModal';
import {
  handleGitHubAuthentication,
  GITHUB_CHANNEL_NAME,
  GITHUB_AUTHENTICATED,
  GITHUB_INSTALLED,
} from '@utils/github';
import useEventTracking from '@hooks/useEventTracking';
import {
  useFunctionsQuery,
  useCreateFunctionViaGithubMutation,
} from '@components/Functions/queries';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { appGithubInstalls, appGithubRepos } from '@utils/resources/github';
import { api, directApi, handleResponseOptions } from '@services/apiService';

import { FunctionCreate } from './FunctionCreate';

export const githubInstallsQuery = (teamUuid, appUuid) => ({
  queryKey: ['githubInstalls', teamUuid, appUuid],
  queryFn: () =>
    api
      .get(
        appGithubInstalls(teamUuid, appUuid),
        handleResponseOptions('githubInstallsQuery')
      )
      .json(),
});

const githubReposQuery = (teamUuid, appUuid, installId) => ({
  queryKey: ['githubInstalls', teamUuid, appUuid, 'repos', installId],
  queryFn: () => {
    let searchParams = new URLSearchParams();
    searchParams.append('githubInstallId', installId);

    return directApi
      .get(appGithubRepos(teamUuid, appUuid), {
        searchParams,
        ...handleResponseOptions('githubReposQuery'),
      })
      .json();
  },
  select: (data) => data.repos,
  enabled: installId != null,
});

const templates = [
  {
    repo: 'template-node-hello-function',
    name: 'Node.js Starter Template',
    description: 'A simple Hello World Function written in Node.js.',
    accentColor: '#63e',
  },
  {
    repo: 'template-python-hello-function',
    name: 'Python Starter Template',
    description: 'A simple Hello World Function written in Python.',
    accentColor: '#f22f46',
  },
];

export const INSTALL_URL = `https://github.com/apps/${
  import.meta.env.VITE_GITHUB_APP
}/installations/new`;

const selectExitingFunctionsName = (functions) =>
  functions.map((func) => func.name);

export const FunctionGithubCreate = () => {
  const [isGithubLoading, setIsGithubLoading] = useState(false);
  const { teamUuid, appUuid } = useParams();
  const { data: existingFunctionNames } = useFunctionsQuery(
    selectExitingFunctionsName
  );
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [path, setPath] = useState(null);
  const [selectedInstall, setSelectedInstall] = useState(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const { closeModal } = useModal();
  const trackEvent = useEventTracking();

  // Use isFetching to disable button if request is in flight
  const { data: installsUserData, isFetching: isInstallsFetching } = useQuery({
    ...githubInstallsQuery(teamUuid, appUuid),
    onSuccess: (newData) => {
      const installations = newData.installations ?? [];
      const firstInstall = installations[0];

      if (firstInstall != null) {
        setSelectedInstall(firstInstall);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { data: repos, isInitialLoading: isReposLoading } = useQuery(
    githubReposQuery(teamUuid, appUuid, selectedInstall?.id)
  );

  const {
    mutateAsync: createFunctionMutateAsync,
    isLoading: isCreateFunctionLoading,
  } = useCreateFunctionViaGithubMutation();

  const githubInstalls = useMemo(() => {
    const installations = installsUserData?.installations ?? [];

    return installations;
  }, [installsUserData]);

  const githubUser = useMemo(() => {
    const authedUser = installsUserData?.authedUser;

    return authedUser;
  }, [installsUserData]);

  const handleAuthChange = () => {
    if (isEmpty(githubInstalls)) {
      queryClient.invalidateQueries({
        queryKey: ['githubInstalls', teamUuid, appUuid],
      });
    }
  };

  useBroadcastChannel(GITHUB_CHANNEL_NAME, (msg) => {
    if ([GITHUB_AUTHENTICATED, GITHUB_INSTALLED].includes(msg)) {
      handleAuthChange();
    }
  });

  const handleSelectedInstall = async (install) => {
    const foundInstall = githubInstalls.find(
      (existingInstall) => existingInstall.account.login === install.value
    );

    if (foundInstall) {
      setSelectedInstall(foundInstall);
    }
    return foundInstall;
  };

  const handleFunctionCreate = useCallback(
    async ({ selectedRepo, functionName, privateRepo, subdirectory }) => {
      const repo =
        path === 'template'
          ? functionName
          : repos.find((repo) => repo.name === selectedRepo.label).name;

      const { func, token } = await createFunctionMutateAsync(
        {
          repoName: repo,
          repoOwner: selectedInstall?.account.login,
          installationId: selectedInstall?.id,
          templateRepo: selectedTemplate?.repo,
          githubAccountType: selectedInstall.account.type,
          privateRepo,
          subdirectory,
        },
        {
          onError: async (err) => {
            const error = await err.response.json();

            switch (err?.code) {
              case 'unique-validation-error':
                toast.error('You already have a Function with this name.', {
                  id: 'error',
                });
                break;
              case 'resource-exhausted':
                toast.error(error.message, {
                  id: 'error',
                });
                break;
              default:
                toast.error(error.message, {
                  id: 'error',
                });
                break;
            }
          },
        }
      );
      toast.success('Your Function was created!');

      navigate(`/${teamUuid}/${appUuid}/functions/${func.name}/general`, {
        state: { token },
      });
      trackEvent('New Function', {
        type: path === 'template' ? 'Template' : 'Imported',
      });
      closeModal();
    },
    [
      appUuid,
      closeModal,
      createFunctionMutateAsync,
      navigate,
      path,
      repos,
      selectedInstall?.account.login,
      selectedInstall?.account.type,
      selectedInstall?.id,
      selectedTemplate?.repo,
      trackEvent,
    ]
  );

  return (
    <FunctionCreate
      onClickPath={setPath}
      onClickAuth={async () => {
        setIsGithubLoading(true);
        try {
          await handleGitHubAuthentication({ teamUuid, appUuid });
        } finally {
          setIsGithubLoading(false);
        }
      }}
      onClickInstall={() => {
        window.open(
          INSTALL_URL,
          'installEvervaultWindow',
          'height=700, width=800'
        );
      }}
      onClickSelectTemplate={setSelectedTemplate}
      onClickCreate={handleFunctionCreate}
      onChangeInstall={handleSelectedInstall}
      gitHubUser={githubUser}
      gitHubInstalls={githubInstalls.map((install) => ({
        value: install.account.login,
        label: install.account.login,
      }))}
      selectedInstall={selectedInstall}
      path={path}
      selectedTemplate={selectedTemplate}
      templates={templates}
      loading={
        isGithubLoading ||
        isInstallsFetching ||
        isCreateFunctionLoading ||
        (isReposLoading && !selectedTemplate)
      }
      repos={repos}
      reposLoading={isReposLoading}
      existingFunctionNames={existingFunctionNames ?? []}
    />
  );
};
