import styles from './EmptyStateDiagram.module.css';
import { Box, Check, MousePointer2, X } from 'lucide-react';
import Diagram from '@common/Diagram';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';
import { useState } from 'react';
import { Button } from '@common/Button/Button';
import { withErrorBoundary } from '@sentry/react';

function EmptyStateDiagram() {
  const [key, setKey] = useState(Math.random().toString(36).substring(7));
  const [showReplayButton, setShowReplayButton] = useState(false);

  const MotionButton = motion(Button);

  return (
    <motion.div className={styles.container} key={key}>
      <div className={styles.group}>
        <div className={styles.serviceContainer}>
          <Diagram.Service
            payload={{ key: 'PCR', value: '0xA', highlight: true }}
            icon={<MousePointer2 />}
          >
            Client
          </Diagram.Service>
          <motion.div
            className={styles.status}
            transition={{
              delay: 3.75,
              type: 'spring',
              stiffness: 300,
              damping: 18,
            }}
            initial={{ opacity: 0, y: 0, scale: 0.5 }}
            animate={{
              opacity: 1,
              y: -18,
              scale: 1,
            }}
          >
            <Check width={12} strokeWidth={3} />
          </motion.div>
        </div>
        <div className={styles.connection}>
          <svg height={1.5} width="auto">
            <line
              x1="0"
              y1="0"
              x2="100%"
              y2="0"
              stroke="var(--grey-60)"
              strokeOpacity={0.5}
              fill="none"
              strokeWidth={2}
              strokeDasharray="1 4"
            />
          </svg>
          <motion.div
            className={styles.request}
            initial={{ left: '0%' }}
            animate={{ left: '100%' }}
            transition={{
              duration: 1.5,
              delay: 0.25,
              ease: 'linear',
              repeat: 1,
              repeatDelay: 2.5,
            }}
          />
          <motion.div
            className={styles.response}
            initial={{ left: '100%' }}
            animate={{ left: '0%' }}
            transition={{ duration: 1.5, delay: 2.25, ease: 'linear' }}
          />
          <motion.div
            className={styles.message}
            transition={{
              duration: 2,
              ease: 'easeOut',
              delay: 0.25,
              times: [0, 0.15, 0.95, 1],
            }}
            initial={{ x: '-50%' }}
            animate={{ opacity: [0, 1, 1, 0], y: [0, -12, -12, -12] }}
          >
            Client Hello
          </motion.div>
          <motion.div
            className={styles.message}
            transition={{
              duration: 2,
              ease: 'easeOut',
              delay: 2.25,
              times: [0, 0.15, 0.95, 1],
            }}
            initial={{ x: '-50%' }}
            animate={{ opacity: [0, 1, 1, 0], y: [0, -12, -12, -12] }}
          >
            Server Hello
          </motion.div>
          <motion.div
            className={styles.message}
            transition={{
              duration: 2,
              ease: 'easeOut',
              delay: 4.5,
              times: [0, 0.15, 0.95, 1],
            }}
            initial={{ x: '-50%' }}
            animate={{ opacity: [0, 1, 1, 0], y: [0, -12, -12, -12] }}
            onAnimationComplete={() => setShowReplayButton(true)}
          >
            <span className={styles.post}>POST</span> /sign-txn
          </motion.div>
        </div>
        <Diagram.Service
          icon={<Box />}
          payload={{ key: 'PCR', value: '0xA', highlight: true }}
        >
          Enclave
        </Diagram.Service>
      </div>
      <div className={styles.group}>
        <div className={styles.serviceContainer}>
          <Diagram.Service
            payload={{ key: 'PCR', value: '0xA', highlight: true }}
            icon={<MousePointer2 />}
          >
            Client
          </Diagram.Service>
          <motion.div
            className={cn(styles.status, styles.statusInvalid)}
            transition={{
              delay: 3.75,
              type: 'spring',
              stiffness: 300,
              damping: 18,
            }}
            initial={{ opacity: 0, y: 0, scale: 0.5 }}
            animate={{
              opacity: 1,
              y: -18,
              scale: 1,
            }}
          >
            <X width={12} strokeWidth={3} />
          </motion.div>
        </div>
        <div className={styles.connection}>
          <svg height={1.5} width="auto">
            <line
              x1="0"
              y1="0"
              x2="100%"
              y2="0"
              stroke="var(--grey-60)"
              strokeOpacity={0.5}
              fill="none"
              strokeWidth={2}
              strokeDasharray="1 4"
            />
          </svg>
          <motion.div
            className={styles.request}
            initial={{ left: '0%' }}
            animate={{ left: '100%' }}
            transition={{
              duration: 1.5,
              delay: 0.25,
              ease: 'linear',
            }}
          />
          <motion.div
            className={styles.response}
            initial={{ left: '100%' }}
            animate={{ left: '0%' }}
            transition={{ duration: 1.5, delay: 2.25, ease: 'linear' }}
          />
          <motion.div
            className={styles.message}
            transition={{
              duration: 2,
              ease: 'easeOut',
              delay: 0.25,
              times: [0, 0.15, 0.95, 1],
            }}
            initial={{ x: '-50%' }}
            animate={{ opacity: [0, 1, 1, 0], y: [0, -12, -12, -12] }}
          >
            Client Hello
          </motion.div>
          <motion.div
            className={styles.message}
            transition={{
              duration: 2,
              ease: 'easeOut',
              delay: 2.25,
              times: [0, 0.15, 0.95, 1],
            }}
            initial={{ x: '-50%' }}
            animate={{ opacity: [0, 1, 1, 0], y: [0, -12, -12, -12] }}
          >
            Server Hello
          </motion.div>
        </div>
        <Diagram.Service icon={<Box />} payload={{ key: 'PCR', value: '0xB' }}>
          Enclave
        </Diagram.Service>
      </div>
      <AnimatePresence>
        {showReplayButton && (
          <MotionButton
            onClick={() => {
              setKey(Math.random().toString(36).substring(7));
              setShowReplayButton(false);
            }}
            className={styles.replay}
            size="sm"
            style={{ x: '-50% ' }}
            initial={{ opacity: 0, y: 12 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 12 }}
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 25,
            }}
          >
            Replay
          </MotionButton>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default withErrorBoundary(EmptyStateDiagram, { fallback: <div /> });
