interface AccessTokenStore {
  setGetTokenHandler(handler: () => Promise<string>): void;
  setLogoutHandler(handler: () => Promise<void>): void;
  getToken(): Promise<string | undefined>;
  logout: () => Promise<void>;
  getTokenHandler?: () => Promise<string>;
  logoutHandler?: () => Promise<void>;
}

export const accessTokenStore: AccessTokenStore = {
  setLogoutHandler(handler: () => Promise<void>) {
    this.logoutHandler = handler;
  },
  setGetTokenHandler(handler: () => Promise<string>) {
    this.getTokenHandler = handler;
  },
  async getToken() {
    if (this.getTokenHandler) {
      return this.getTokenHandler();
    }
  },
  async logout() {
    if (this.logoutHandler) {
      return this.logoutHandler();
    }
  },
};

export const getAccessToken = () => {
  return accessTokenStore.getToken();
};
