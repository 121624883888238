import { useContext } from 'react';
import { DiagramContext } from '.';
import css from './Nodes.module.css';

export default function DiagramGroup({ children }) {
  const { direction } = useContext(DiagramContext);

  return (
    <div data-direction={direction} className={css.group}>
      {children}
    </div>
  );
}
