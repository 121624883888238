import { motion } from 'framer-motion';

import styles from './Spinner.module.css';

export const Spinner = ({
  size = 'var(--token-24)',
  width = '2px',
  color = 'var(--primary-100)',
  background = 'var(--grey-20)',
  ...props
}) => (
  <motion.span aria-label="Loading…" tabIndex={-1} {...props}>
    <span
      className={styles.spinner}
      style={{
        '--size': size,
        '--border-width': width,
        '--border-color': color,
        '--background-color': background,
      }}
    />
  </motion.span>
);
