import classNames from 'classnames';
import { createContext, useEffect, useId, useRef, useState } from 'react';
import css from './Nodes.module.css';
import DiagramNode from './DiagramNode';
import DiagramGroup from './DiagramGroup';
import DiagramLogo from './DiagramLogo';
import DiagramConnection from './DiagramConnection';
import DiagramRequest from './DiagramRequest';
import DiagramService from './DiagramService';
import DiagramNodes from './DiagramNodes';
import { withErrorBoundary } from '@sentry/react';
export const DiagramContext = createContext();

function Diagram({ children, className, direction = 'horizontal' }) {
  const id = useId();
  const [mounted, setMounted] = useState(false);
  const [resizing, setResizing] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    const isTouchDevice =
      'ontouchstart' in document.documentElement ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
    if (isTouchDevice) return;

    const handleResize = () => {
      clearTimeout(timer.current);
      setResizing(true);

      timer.current = setTimeout(() => {
        setResizing(false);
      }, 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  const classes = classNames(css.diagram, className);

  return (
    <DiagramContext.Provider value={{ id, resizing, direction }}>
      <div id={`nodes-${id}`} className={classes} data-direction={direction}>
        {children}
      </div>
    </DiagramContext.Provider>
  );
}

Diagram.Node = DiagramNode;
Diagram.Group = DiagramGroup;
Diagram.Logo = DiagramLogo;
Diagram.Connection = DiagramConnection;
Diagram.Request = DiagramRequest;
Diagram.Service = DiagramService;
Diagram.Nodes = DiagramNodes;

export default withErrorBoundary(Diagram, { fallback: <div /> });
