import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import transitions from '@utils/transitions';

import styles from './Switch.module.css';

export default function Switch({
  onClick,
  isOn = false,
  disabled,
  disableAnimation,
}) {
  const [on, setOn] = useState(isOn);

  const handleOnClick = () => {
    if (!disableAnimation) setOn(!on);
    onClick();
  };

  useEffect(() => {
    setOn(isOn);
  }, [isOn]);

  return (
    <motion.div className={styles.switch}>
      <button
        type="button"
        className={styles.toggle}
        onClick={handleOnClick}
        role="switch"
        aria-label={on ? 'On' : 'Off'}
        aria-checked={on}
        id="toggle"
        disabled={disabled}
      >
        <motion.div
          className={styles.knob}
          initial={false}
          animate={{ x: on ? '18px' : '2px' }}
          transition={transitions.spring}
        />
      </button>
    </motion.div>
  );
}
