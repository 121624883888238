import * as RadixTabs from '@radix-ui/react-tabs';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { ReactElement, useId, useMemo, useState } from 'react';
import css from './styles.module.css';
import { Tab } from './Tab';
import { cn } from '@vault/utilities';
import { extractLabelsFromTabs } from './utilities';
import { Trigger } from './Trigger';
import { TabsContext } from './Context';

type TabsProps = RadixTabs.TabsProps & {
  children: ReactElement<typeof Tab> | ReactElement<typeof Tab>[];
  className?: string;
};

function Tabs({ children, className, ...props }: TabsProps) {
  const id = useId();
  const labels = extractLabelsFromTabs(children);
  const [value, setValue] = useState(labels[0]);

  const context = useMemo(() => {
    return { value };
  }, [value]);

  return (
    <TabsContext.Provider value={context}>
      <LayoutGroup id={id}>
        <RadixTabs.Root value={value} onValueChange={setValue} {...props}>
          <RadixTabs.List className={css.list}>
            <div className={cn(css.tabs, className)}>
              {labels.map((label) => (
                <Trigger key={label} label={label} />
              ))}
            </div>
          </RadixTabs.List>
          <AnimatePresence mode="wait" initial={false}>
            <div>{children}</div>
          </AnimatePresence>
        </RadixTabs.Root>
      </LayoutGroup>
    </TabsContext.Provider>
  );
}

const TabsNamespace = Object.assign(Tabs, { Tab });
export { TabsNamespace as Tabs };
