export function Spinner() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 11c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1"
      />
    </svg>
  );
}
