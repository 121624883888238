import { BroadcastChannel } from 'broadcast-channel';
import { useEffect, useRef } from 'react';

export default function useBroadcastChannel(
  name: string,
  onMessage?: (msg: any) => void
) {
  const channel = useRef<BroadcastChannel | null>(null);

  useEffect(() => {
    channel.current = new BroadcastChannel(name, { webWorkerSupport: false });
    if (onMessage) {
      channel.current.onmessage = onMessage;
    }

    return () => {
      channel.current?.close();
    };
  }, [onMessage]);

  return channel.current;
}
