import { ChartCard } from '@common/ChartCard/ChartCard';
import time from '@utils/time';
import { useResourceVolumne } from '@common/ChartCard/queries';

export const FunctionCard = ({ func }) => {
  const { data } = useResourceVolumne(func.uuid);

  return (
    <ChartCard
      name={func.name}
      data={data}
      text={time(func.createdAt).fromNow()}
      to={`${func.name}/general`}
    />
  );
};
