import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';
import { AppError } from '@common/AppError/AppError';
import { Card } from '@common/Card/Card';
import { IconRefresh } from '@common/Icons/Icons';
import LayoutLoader from './LayoutLoader';

function LayoutFallback({ error }) {
  const location = useLocation();

  const handleReset = () => {
    window.location = location.pathname;
  };

  if (error.message.includes('Failed to fetch dynamically imported module')) {
    return <LayoutLoader />;
  }

  return (
    <div className="pt-16 row align-items-center justify-content-center">
      <div className="col-xs-10 col-sm-8 col-md-6">
        <Card className="p-24">
          <AppError
            onRetry={handleReset}
            buttonLabel="Try again"
            buttonIcon={IconRefresh}
          >
            There was an error while loading this page. We’ve been notified and
            are working to get things back to normal quickly.
          </AppError>
        </Card>
      </div>
    </div>
  );
}

export default function LayoutErrorBoundary({ children }) {
  const location = useLocation();

  return (
    <Sentry.ErrorBoundary
      key={location.pathname}
      fallback={({ error }) => <LayoutFallback error={error} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
