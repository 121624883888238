import classNames from 'classnames';
import styles from './styles.module.css';
import { CSSProperties, createContext, useContext, useMemo } from 'react';
import SkeletonText from '@common/SkeletonText';

const TableContext = createContext<{ headings: string[] }>({
  headings: [],
});

interface TableProps extends React.ComponentPropsWithoutRef<'table'> {
  className?: string;
  headings?: string[];
  rowPadding?: string;
  children: React.ReactNode;
}

const Table = ({
  children,
  className,
  headings = [],
  rowPadding,
  ...props
}: TableProps) => {
  return (
    <TableContext.Provider value={{ headings }}>
      <table
        role="table"
        className={classNames(styles.table, className)}
        style={{ '--row-padding': rowPadding } as CSSProperties}
        {...props}
      >
        {headings.length > 0 && (
          <thead className={styles.thead}>
            <TableRow>
              {headings.map((heading, i) => (
                <TableHeading key={i}>{heading}</TableHeading>
              ))}
            </TableRow>
          </thead>
        )}
        <tbody className={styles.tbody}>{children}</tbody>
      </table>
    </TableContext.Provider>
  );
};

interface TableRowProps extends React.ComponentPropsWithoutRef<'tr'> {
  className?: string;
  children: React.ReactNode;
}

const TableRow = ({
  children,
  className,
  onClick,
  ...props
}: TableRowProps) => {
  return (
    <tr
      role="row"
      className={classNames(styles.row, className)}
      data-clickable={onClick ? true : undefined}
      onClick={onClick}
      {...props}
    >
      {children}
    </tr>
  );
};

interface TableCellProps extends React.ComponentPropsWithoutRef<'td'> {
  className?: string;
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode;
}

const TableCell = ({
  children,
  className,
  align,
  ...props
}: TableCellProps) => {
  const classes = classNames(styles.cell, className);

  return (
    <td role="cell" data-align={align} className={classes} {...props}>
      {children}
    </td>
  );
};

const TableHeading = ({
  children,
  className,
  align,
  ...props
}: TableCellProps) => {
  const classes = classNames(styles.cell, styles.heading, className);

  return (
    <th role="columnheader" data-align={align} className={classes} {...props}>
      {children}
    </th>
  );
};

const TableEmpty = ({ children }: { children: React.ReactNode }) => {
  return (
    <Table.Row>
      <Table.Cell colSpan={10}>
        <div className={styles.empty}>{children}</div>
      </Table.Cell>
    </Table.Row>
  );
};

const TableLoading = ({ rows = 3 }: { rows?: number }) => {
  const { headings } = useContext(TableContext);
  const columns = headings.length || 1;

  const skeletons = useMemo(() => {
    function randomPercentage() {
      const value = Math.floor(Math.random() * (80 - 20 + 1)) + 20;
      return `${value}%`;
    }

    return Array.from({ length: rows }, () => {
      return Array.from({ length: columns }, randomPercentage);
    });
  }, [rows]);

  return skeletons.map((row, r) => (
    <Table.Row key={r}>
      {row.map((width, i) => (
        <Table.Cell key={i}>
          <SkeletonText width={width} height="12px" />
        </Table.Cell>
      ))}
    </Table.Row>
  ));
};

Table.Row = TableRow;
Table.Cell = TableCell;
Table.Heading = TableHeading;
Table.Empty = TableEmpty;
Table.Loading = TableLoading;

export { Table };
