import * as Primitive from '@radix-ui/react-dialog';
import { DialogState } from './useDialog';
import { motion, AnimatePresence } from 'framer-motion';
import css from './Dialog.module.css';
import { DialogModal } from './DialogModal';
import { createContext, useContext } from 'react';
import { DialogConfirmation } from './DialogConfirmation';
import { DialogSection } from './DialogSection';
export { default as useDialog } from './useDialog';
export type { DialogState } from './useDialog';

type DialogProps = {
  state: DialogState;
  children: React.ReactNode;
  trigger?: React.ReactNode;
  className?: string;
};

const DialogContext = createContext<DialogState | null>(null);
export const useDialogContext = () => useContext(DialogContext);

function Dialog({ state, children, className, trigger }: DialogProps) {
  const classes = [css.window, className].filter(Boolean).join(' ');

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <DialogContext.Provider value={state}>
      <Primitive.Root open={state.isOpen} onOpenChange={state.setIsOpen}>
        {trigger && <Primitive.Trigger asChild>{trigger}</Primitive.Trigger>}
        <AnimatePresence>
          {state.isOpen && (
            <Primitive.Portal forceMount>
              <Primitive.Overlay asChild>
                <motion.div
                  className={css.overlay}
                  {...OVERLAY_ANIMATIONS}
                  onClick={handleClick}
                >
                  <Primitive.Content asChild>
                    <motion.div className={classes} {...WINDOW_ANIMATIONS}>
                      {children}
                    </motion.div>
                  </Primitive.Content>
                </motion.div>
              </Primitive.Overlay>
            </Primitive.Portal>
          )}
        </AnimatePresence>
      </Primitive.Root>
    </DialogContext.Provider>
  );
}

const OVERLAY_ANIMATIONS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

const WINDOW_ANIMATIONS = {
  initial: { y: 12 },
  animate: { y: 0 },
  exit: { y: 12 },
};

const DialogNamespace = Object.assign(Dialog, {
  Modal: DialogModal,
  Confirmation: DialogConfirmation,
  Section: DialogSection,
});

export { DialogNamespace as Dialog };
