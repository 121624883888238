import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Heading from '@common/Heading';
import { Restricted } from '@components/Restricted';
import { ADMIN } from '@utils/roles';
import Table from 'rc-table';
import { useWorkflowsQuery } from '@components/Workflow/queries';
import useModal from '@hooks/useModal';
import CreateUpdateWorkflowModal from '@components/Workflow/CreateUpdateWorkflowModal';
import { useFunctionsQuery } from '@components/Functions/queries';
import { Loading } from '@common/Loading/Loading';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { Tooltip } from '@common/Tooltip/Tooltip';
import { IconCopy } from '@common/Icons/Icons';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTeamQuery } from '@queries/team';
import { Button } from '@common/Button/Button';
import EmptyState from '@components/Workflow/EmptyState';
import { Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Menu from '@common/Menu';
import { IconOptions } from '@common/Icons/Icons';
import DeleteWorkflowModal from '@components/Workflow/DeleteWorkflowModal';

const Workflow = () => {
  const flags = useFlags();

  if (!flags.enableWorkflows) {
    return <Navigate to="/" />;
  }

  const { data: workflows, isInitialLoading: initLoadingWorkflow } =
    useWorkflowsQuery();
  const { openModal } = useModal();
  const { copy } = useCopyToClipboard();
  const { teamUuid, appUuid } = useParams();

  const { data: team, isInitialLoading: initLoadingTeam } =
    useTeamQuery(teamUuid);
  const { data: functionsData, isInitialLoading: initLoadingFunctions } =
    useFunctionsQuery();

  if (initLoadingFunctions || initLoadingTeam || initLoadingWorkflow)
    return <Loading />;

  const integrations = team.thirdPartyIntegrations.filter(
    (i) => i.vendor === 'googleSheets'
  );

  const PUBLIC_API_URL =
    import.meta.env.VITE_EV_PUBLIC_API_URL ?? 'https://api.evervault.com';

  const handleCreateWorkflow = () =>
    openModal(<CreateUpdateWorkflowModal integrations={integrations} />, {
      title: 'Create Workflow',
    });

  const handleDelete = (workflow) => {
    openModal(<DeleteWorkflowModal workflow={workflow} />, {
      title: 'Delete Workflow',
    });
  };

  const editWorkflow = (workflow) => {
    openModal(
      <CreateUpdateWorkflowModal
        integrations={integrations}
        initialData={workflow}
      />,
      {
        title: `Edit Workflow ${workflow.name}`,
      }
    );
  };

  const copyUrl = (workflowUrl) => {
    copy(workflowUrl);
    toast.success('Workflow url copied to clipboard', { id: 'copy' });
  };

  const workflowsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'workflow-name',
      ellipsis: true,
    },
    {
      title: 'Integration',
      dataIndex: 'role',
      key: 'team-role',
      render: (_, row) => {
        if (row.thirdPartyIntegration?.vendor === 'googleSheets') {
          return 'Google Sheets';
        } else {
          // Only googleSheets is supported for now
          return null;
        }
      },
    },
    {
      title: 'Url',
      key: 'url',
      dataIndex: 'url',
      ellipsis: true,
      width: 300,
      render: (_, row) => {
        return row.type === 'form'
          ? `${PUBLIC_API_URL}/workflows/${row.uuid}/submit-form`
          : `${PUBLIC_API_URL}/workflows/${row.uuid}/run`;
      },
    },
    {
      title: '',
      key: 'copy',
      render: (_, row) => {
        const workflowUrl =
          row.type === 'form'
            ? `${PUBLIC_API_URL}/workflows/${row.uuid}/submit-form`
            : `${PUBLIC_API_URL}/workflows/${row.uuid}/run`;
        return (
          <Tooltip content="Click to copy">
            <Button
              size="xs"
              className="ml-8"
              onClick={() => copyUrl(workflowUrl)}
            >
              <IconCopy className="mx-0" />
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'delete',
      render: (_, row) => {
        return (
          <div>
            <div>
              <Menu>
                <Menu.Trigger>
                  <Button
                    size="xs"
                    variant="text"
                    aria-label="Workflow options"
                    className="d-inline-flex"
                  >
                    <IconOptions className="mx-auto" />
                  </Button>
                </Menu.Trigger>
                <Menu.List>
                  <Menu.Item onClick={() => handleDelete(row)}>
                    Delete
                  </Menu.Item>
                  <Menu.Item onClick={() => editWorkflow(row)}>Edit</Menu.Item>
                </Menu.List>
              </Menu>
            </div>
          </div>
        );
      },
    },
  ].filter(Boolean);
  const data = workflows;
  return (
    <div>
      <Heading
        title="Workflows"
        actions={
          <Restricted.Button
            roles={ADMIN}
            size="sm"
            onClick={handleCreateWorkflow}
          >
            Add workflow
          </Restricted.Button>
        }
      />
      {workflows?.length === 0 && <EmptyState />}
      {workflows?.length > 0 && (
        <Table columns={workflowsColumns} data={data} className="mb-32" />
      )}
    </div>
  );
};

export default withLDConsumer()(Workflow);
