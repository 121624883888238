import { useUserQuery } from '@queries/user';
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export function LaunchDarklyProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const testFlags = useTestFlags();

  return (
    <LDProvider
      flags={testFlags}
      clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}
    >
      {children}
    </LDProvider>
  );
}

export function LaunchDarklySubscriber() {
  const ld = useLDClient();
  const config = useLDConfig();

  useEffect(() => {
    ld?.identify(config);
  }, [ld, config]);

  return null;
}

function useLDConfig() {
  const { teamUuid } = useParams();
  const { data } = useUserQuery();

  const context = useMemo(() => {
    const userId = data?.id;

    if (userId === null) {
      return { key: 'user-not-identified' };
    }

    return {
      key: `team-${teamUuid}-user-${userId}`,
      custom: {
        team: `team-${teamUuid}`,
      },
    };
  }, [teamUuid, data?.id]);

  return context;
}

function useTestFlags() {
  if (!import.meta.env.DEV) return undefined;

  return window.__FLAGS__;
}
