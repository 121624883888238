import classNames from 'classnames';
import css from './Nodes.module.css';
import { motion } from 'framer-motion';

export default function DigramNode({ id, children, className }) {
  const classes = classNames(css.node, className);

  return (
    <motion.div id={`node-${id}`} className={classes}>
      {children}
    </motion.div>
  );
}
