import { descend, prop, sort } from 'ramda';
import format from '@utils/format';

import { Box, PlayIcon, Eye } from 'lucide-react';
import { EnclaveCard } from './EnclaveCard/EnclaveCard';
import EmptyState from '@common/EmptyState';
import { Tag } from '@common/Tag/Tag';
import EmptyStateAsset from '@images/outbound-relay-empty-state.png';
import EmptyStateDiagram from './EmptyStateDiagram/EmptyStateDiagram';
import styles from './EnclaveList.module.css';
import {
  ENCLAVE_STATE_FILTERS,
  useInfiniteEnclavesQuery,
} from '@components/Enclaves/queries';
import useModal from '@hooks/useModal';
import YouTubeEmbed from '@common/YouTubeEmbed/YouTubeEmbed';
import Heading from '@common/Heading';
import time from '@utils/time';
import { useEnclaveLimits } from '@components/Enclaves/utils';
import { Tooltip } from '@common/Tooltip/Tooltip';
import Select from '@common/Select';
import { useState } from 'react';
import { InfiniteScroll } from '@routes/TeamSettings/TeamActivity/InfiniteScroll';

// display deleted enclaves if the deletion happened < 10d ago
const filterDeleted = (enclaves) => {
  const now = time();

  return enclaves.filter((enclave) => {
    if (enclave.state !== 'deleted') return true;

    return now.diff(enclave.updatedAt, 'day') <= 10;
  });
};

function useLimits() {
  const { maxInstances, instancesInUse, tier, expirationDate, hasExpired } =
    useEnclaveLimits();

  let isTrial = tier === 'trial';
  let trialTimeRemainingMessage;

  if (!expirationDate && isTrial) {
    trialTimeRemainingMessage =
      'Trial not started - deploy to start 14 days of free access';
  } else if (hasExpired && isTrial) {
    trialTimeRemainingMessage = 'Trial has expired';
  } else if (isTrial) {
    trialTimeRemainingMessage = `${time(expirationDate)
      .fromNow()
      .replace('in ', '')} remaining`;
  } else if (!tier) {
    trialTimeRemainingMessage = (
      <>
        Contact <a href="mailto:sales@evervault.com">sales</a> to start using
        Enclaves.
      </>
    );
  }

  return { maxInstances, instancesInUse, tier, trialTimeRemainingMessage };
}

export default function EnclaveList() {
  const [filter, setFilter] = useState(ENCLAVE_STATE_FILTERS.ACTIVE);
  const { data, fetchNextPage } = useInfiniteEnclavesQuery(filter, {
    suspense: true,
  });
  const enclaves = data?.pages?.flatMap(({ enclaves }) => enclaves) ?? [];

  const enclavesByNewestFirst = enclaves
    ? filterDeleted(sort(descend(prop('createdAt')), enclaves))
    : [];
  const { openModal } = useModal();

  const handleWatchTutorial = () => {
    openModal(<YouTubeEmbed embedId="BlU3z4_ZH78" />, { title: '' });
  };

  const { maxInstances, instancesInUse, tier, trialTimeRemainingMessage } =
    useLimits();

  const tierTag =
    tier === 'trial' ? (
      <Tag>Free</Tag>
    ) : tier ? (
      <Tag>{format.capitalizeFirstLetter(tier)}</Tag>
    ) : null;

  if (enclavesByNewestFirst.length === 0) {
    return (
      <>
        <Heading
          title={<>Enclaves {tierTag}</>}
          text={trialTimeRemainingMessage}
        />
        <EmptyState
          asset={<EmptyStateDiagram />}
          title="Build, Deploy and Attest"
          description="Enclaves are the easiest way to build, deploy and scale Secure Enclaves.
      Go from a Dockerfile to an AWS Nitro Enclave in 15 minutes."
        >
          <EmptyState.PrimaryCard
            tagline="Get started"
            title="Deploy your first Enclave with the Getting Started docs."
            href="https://docs.evervault.com/primitives/enclaves/getting-started"
            asset={EmptyStateAsset}
            cta="Get Started"
          />
          <EmptyState.SecondaryCard
            title="Learn more"
            href="https://evervault.com/primitives/enclaves"
            icon={<Box width={17} />}
          />
          <EmptyState.SecondaryCard
            title="Watch an Overview"
            onClick={handleWatchTutorial}
            icon={<PlayIcon width={17} />}
          />
        </EmptyState>
      </>
    );
  }

  const replicasInUse = (
    <Tooltip content="Enclaves replicas are shared across all apps in your team">
      <Tag data-type="info" style={{ whiteSpace: 'nowrap' }}>
        {instancesInUse}/{maxInstances} replicas in use
      </Tag>
    </Tooltip>
  );

  return (
    <>
      <Heading
        title={
          <>
            Enclaves {tierTag} {replicasInUse}
          </>
        }
        text={trialTimeRemainingMessage}
        actions={
          <Select
            prefix={<Eye />}
            size="sm"
            value={filter}
            onChange={setFilter}
            label="Filter"
          >
            <Select.Option value={ENCLAVE_STATE_FILTERS.ACTIVE}>
              Active
            </Select.Option>
            <Select.Option value={ENCLAVE_STATE_FILTERS.DELETED}>
              Deleted
            </Select.Option>
          </Select>
        }
      />
      <div className={styles.cards}>
        {enclavesByNewestFirst.map((enclave) => {
          return <EnclaveCard key={enclave.uuid} enclave={enclave} />;
        })}
        <InfiniteScroll fetchNextPage={fetchNextPage} />
      </div>
    </>
  );
}
