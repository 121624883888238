import cn from 'classnames';
import { motion } from 'framer-motion';

import { IconArrow } from '@common/Icons/Icons';
import transitions from '@utils/transitions';
import { Tooltip } from '@common/Tooltip/Tooltip';
import styles from './FormDivider.module.css';

export const FormDivider = ({ title, ...props }) => (
  <motion.div
    className={styles.container}
    animate={{ height: 64 }}
    exit={{ height: 0 }}
    transition={{
      when: 'beforeChildren',
      staggerChildren: 0.6,
    }}
    {...props}
  >
    <motion.div
      className={styles.divider}
      animate={{ scaleX: 1, opacity: 1, transition: transitions.spring }}
      exit={{ scaleX: 0, opacity: 0 }}
    />
    <Tooltip content={title}>
      <motion.div
        className={cn(styles.arrow, title && styles.arrowLink)}
        animate={{
          scale: 1,
          rotate: title ? -180 : 0,
          opacity: 1,
          transition: transitions.spring,
        }}
        exit={{ scale: 0, opacity: 0 }}
      >
        <IconArrow direction="down" />
      </motion.div>
    </Tooltip>
  </motion.div>
);
