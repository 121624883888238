import { useEffect, useState } from 'react';
import { Button } from '@common/Button/Button';
import useModal from '@hooks/useModal';
import { FormInput } from '@common/FormInput/FormInput';
import { Select } from '@vault';
import { Controller, useForm, useFieldArray, useWatch } from 'react-hook-form';
import {
  useUpdateWorkflowMutation,
  useGetHubspotTeamsMutation,
  useCreateWorkflowMutation,
} from '@components/Workflow/queries';
import toast from 'react-hot-toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormField, Card, Flex, ConnectedFields, Input } from '@vault';
import { IconAdd, IconClose } from '@common/Icons/Icons';
import { Restricted } from '@components/Restricted';
import { DEV_OR_ADMIN } from '@utils/roles';

const schema = z.object({
  name: z
    .string()
    .min(1, { message: 'Please enter a name for the Workflow' })
    .max(100, { message: 'Workflow name must be less than 100 characters' }),
  integrationUuid: z.string(),
  targets: z.array(
    z.object({
      teamId: z.string(),
      condition: z.object({
        elementName: z.string(),
        elementValue: z.string(),
      }),
    })
  ),
  workflowUuid: z.string().optional(),
  apiKey: z.string(),
});

const HUBSPOT_TYPE = 'hubspot';

export default function CreateUpdateWorkflowModal({
  integrations,
  initialData,
}) {
  const { closeModal } = useModal();
  const [hubspotTeams, setHubspotTeams] = useState([]);
  const { mutateAsync: createWorkflow } = useCreateWorkflowMutation();
  const { mutateAsync: updateWorkflow } = useUpdateWorkflowMutation();
  const { mutateAsync: getHubspotTeams } = useGetHubspotTeamsMutation();

  const formattedIntegrations = integrations?.map((item) => ({
    label: 'Google Sheets',
    value: item.uuid,
  }));

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: {
      workflowUuid: initialData?.uuid || '',
      name: initialData?.name || '',
      integrationUuid: initialData?.integrationUuid || '',
      apiKey: initialData?.metadata.emailSource.apiKey || '',
      targets: initialData?.metadata.emailSource.targets || [
        { teamId: '', condition: { elementName: '', elementValue: '' } },
      ],
    },
  });

  const [showAdvancedFields, setShowAdvancedFields] = useState(
    initialData?.uuid ? true : false
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'targets',
  });

  const apiKey = useWatch({
    control,
    name: 'apiKey',
  });

  const onNext = async () => {
    try {
      const teams = await getHubspotTeams(apiKey);
      const formattedTeams = teams.map((team) => ({
        label: team.name,
        value: team.id,
      }));
      setHubspotTeams(formattedTeams);
      setShowAdvancedFields(true);
    } catch (error) {
      console.error('Failed to fetch Hubspot teams:', error);
      toast.error('Failed to fetch Hubspot teams');
    }
  };

  const submissionHandler = async ({
    integrationUuid,
    name,
    targets,
    apiKey,
    workflowUuid,
  }) => {
    try {
      const emailSource = {
        type: HUBSPOT_TYPE,
        apiKey: apiKey,
        targets,
      };

      const submitData = {
        name,
        integrationUuid,
        emailSource,
      };
      if (initialData) {
        // Name cannot be updated after create
        delete submitData['name'];
        await updateWorkflow({ workflowUuid, data: submitData });
      } else {
        await createWorkflow(submitData);
      }
      toast.success(
        `${initialData ? 'Workflow updated successfully' : 'Workflow created successfully'}`
      );
      closeModal();
    } catch (error) {
      console.error(error);
      toast.error(
        `${initialData ? 'Updating Workflow failed' : 'Workflow creation failed'}`
      );
    }
  };

  useEffect(() => {
    const apiKey = initialData?.metadata.emailSource.apiKey;
    const loadHubspotTeams = async () => {
      if (apiKey) {
        const teams = await getHubspotTeams(apiKey);
        setHubspotTeams(
          teams.map((team) => ({
            label: team.name,
            value: team.id,
          }))
        );
      }
    };

    loadHubspotTeams();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(submissionHandler)}>
        <Flex direction="column" gap="12px">
          <FormField label="Workflow Name">
            <Input
              {...register('name')}
              error={errors?.name}
              disabled={initialData ? true : false}
            />
          </FormField>
          <FormField label="Select Third Party Integration">
            <Controller
              control={control}
              name="integrationUuid"
              render={({ field }) => (
                <Select {...field}>
                  {formattedIntegrations.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </FormField>
          <FormField label="Hubspot API Key">
            <FormInput
              name="apiKey"
              error={errors?.apiKey}
              helperText="API key with permissions to retrieve user emails from Hubspot"
              inputProps={{
                placeholder: 'API Key',
                disabled: isSubmitting,
                type: 'password',
                ...register('apiKey'),
              }}
            />
          </FormField>
          {!showAdvancedFields && (
            <Button variant="outlined" onClick={() => onNext()} type="button">
              <IconAdd />
              Next
            </Button>
          )}
        </Flex>
        {showAdvancedFields && (
          <Flex direction="column" gap="20px" className="mt-16">
            {fields.map((item, index) => (
              <Card key={item.id}>
                <Card.Section>
                  <Button
                    style={{ position: 'absolute', right: 20, top: 12 }}
                    type="icon"
                    onClick={() => remove(index)}
                  >
                    <IconClose />
                  </Button>
                  <FormField
                    label="What team should emails be sent to?"
                    className="mb-20"
                  >
                    <Controller
                      name={`targets.${index}.teamId`}
                      control={control}
                      render={({ field }) => (
                        <Select {...field}>
                          {hubspotTeams.map((option) => (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    />
                  </FormField>
                  <FormField
                    label="What form elements should trigger the email?"
                    className="mb-20"
                  >
                    <ConnectedFields>
                      <Input
                        error={errors.targets?.[index]?.condition?.elementName}
                        placeholder="Element Name"
                        disabled={isSubmitting}
                        {...register(`targets.${index}.condition.elementName`)}
                      />
                      <Input
                        error={errors.targets?.[index]?.condition?.elementValue}
                        placeholder="Element Value"
                        disabled={isSubmitting}
                        {...register(`targets.${index}.condition.elementValue`)}
                      />
                    </ConnectedFields>
                  </FormField>
                </Card.Section>
              </Card>
            ))}
            <Flex justify="between">
              <Button
                variant="outlined"
                onClick={() =>
                  append({
                    teamId: '',
                    condition: { elementName: '', elementValue: '' },
                  })
                }
                type="button"
              >
                <IconAdd />
                Add Email Rule
              </Button>
            </Flex>
          </Flex>
        )}
        {showAdvancedFields && (
          <div className="pt-20">
            <Restricted.Button roles={DEV_OR_ADMIN} loading={isSubmitting}>
              {initialData ? 'Update Workflow' : 'Create Workflow'}
            </Restricted.Button>
          </div>
        )}
      </form>
    </>
  );
}
