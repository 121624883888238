import css from './styles.module.css';
import { cn } from '@vault';
import { CardSection } from './CardSection';
import { ComponentPropsWithoutRef } from 'react';

type CardProps = ComponentPropsWithoutRef<'div'>;

export function Card({ children, className, ...props }: CardProps) {
  return (
    <div className={cn(css.card, className)} {...props}>
      {children}
    </div>
  );
}

Card.Section = CardSection;
