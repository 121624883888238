export default function Logo(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d="M23 14.3578V19.3629C22.9873 18.4807 22.4067 17.7039 21.5549 17.4293L11.5198 14.1941C10.0748 13.7282 8.49844 13.8707 7.16382 14.5878L1.85819 17.4386C1.34039 17.7168 1.01311 18.2461 1 18.8266L1 13.8215C1.01311 13.241 1.34039 12.7117 1.85819 12.4335L5.2332 10.6201C7.79978 9.241 10.8312 8.96699 13.6101 9.86287L21.5549 12.4242C22.4067 12.6988 22.9873 13.4756 23 14.3578Z"
          fill="#5229BE"
        />
        <path
          d="M1 18.6118L1 13.6068C1.01268 14.4889 1.59327 15.2658 2.44514 15.5404L10.3899 18.1017C13.1688 18.9975 16.2002 18.7235 18.7668 17.3445L22.1418 15.531C22.6596 15.2528 22.9869 14.7235 23 14.143V19.1481C22.9869 19.7286 22.6596 20.2579 22.1418 20.5361L18.7668 22.3496C16.2002 23.7286 13.1688 24.0026 10.3899 23.1068L2.44514 20.5454C1.59327 20.2709 1.01268 19.494 1 18.6118Z"
          fill="var(--primary-100)"
        />
      </g>
      <g>
        <path
          d="M23 5.38814V10.3932C22.9873 9.51108 22.4067 8.73423 21.5549 8.45963L11.5198 5.22443C10.0748 4.75856 8.49844 4.90107 7.16382 5.61818L1.85819 8.46894C1.34039 8.74716 1.01311 9.2765 1 9.85698L1 4.85188C1.01311 4.2714 1.34039 3.74207 1.85819 3.46386L5.2332 1.65042C7.79978 0.271375 10.8312 -0.00264514 13.6101 0.893225L21.5549 3.45453C22.4067 3.72918 22.9873 4.50599 23 5.38814Z"
          fill="#5229BE"
        />
        <path
          d="M1 9.64221L1 4.63712C1.01268 5.51927 1.59327 6.29612 2.44514 6.57076L10.3899 9.13203C13.1688 10.0279 16.2002 9.75389 18.7668 8.37487L22.1418 6.56141C22.6596 6.28319 22.9869 5.75386 23 5.17337V10.1785C22.9869 10.759 22.6596 11.2883 22.1418 11.5665L18.7668 13.3799C16.2002 14.759 13.1688 15.033 10.3899 14.1371L2.44514 11.5758C1.59327 11.3012 1.01268 10.5244 1 9.64221Z"
          fill="var(--primary-100)"
        />
      </g>
    </svg>
  );
}
