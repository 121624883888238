import css from './styles.module.css';
import { ComponentPropsWithoutRef } from 'react';

type ConnectFieldButtonProps = ComponentPropsWithoutRef<'button'>;

export function ConnectFieldButton({
  className,
  ...props
}: ConnectFieldButtonProps) {
  const classes = [css.button, className].filter(Boolean).join(' ');
  return <button className={classes} {...props} />;
}
