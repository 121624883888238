import { BroadcastChannel } from 'broadcast-channel';
import { toast } from 'react-hot-toast';
import * as Sentry from '@sentry/react';

import { api, handleResponseOptions } from '@services/apiService';
import { appGithubLogin } from './resources/github';

const FUNCTION_CREATE_PARAMS_STATE_KEY = 'function-create-params-state';

export const saveParamsState = (state) => {
  sessionStorage.setItem(FUNCTION_CREATE_PARAMS_STATE_KEY, state);
};

const loginWithGithub = async (teamUuid, appUuid) =>
  api
    .get(
      appGithubLogin(teamUuid, appUuid),
      handleResponseOptions('loginWithGithub')
    )
    .json();

export const GITHUB_CHANNEL_NAME = 'github-authentication-channel';
export const GITHUB_AUTHENTICATED = 'github-authenticated';
export const GITHUB_INSTALLED = 'github-installed';

export const createGithubChannel = () =>
  new BroadcastChannel(GITHUB_CHANNEL_NAME, { webWorkerSupport: false });

export const handleGitHubAuthentication = async ({ teamUuid, appUuid }) => {
  try {
    // Subscribe to channel
    const channel = createGithubChannel();
    const { url, tokenPresent } = await loginWithGithub(teamUuid, appUuid);
    if (!tokenPresent && url) {
      const params = { teamUuid, appUuid };
      const state = new URL(url).searchParams.get('state');
      saveParamsState(
        JSON.stringify({
          state,
          params,
        })
      );
      const authWindow = window.open(
        url,
        'installEvervaultWindow',
        'height=700, width=700'
      );

      // Await authentication completion
      const res = await new Promise((resolve, reject) => {
        // Poll for window closing before auth completes
        let intervalHolder = { interval: null };
        intervalHolder.interval = setInterval(async () => {
          if (authWindow.closed) {
            clearInterval(intervalHolder.interval);
            channel.close();
            reject(new Error('Authentication window closed before completion'));
          }
        }, 1000);

        channel.onmessage = async (msg) => {
          if (msg === GITHUB_AUTHENTICATED || msg === GITHUB_INSTALLED) {
            clearInterval(intervalHolder.interval);
            authWindow.close();
            channel.close();
            resolve();
          }
        };
      });

      return res;
    } else if (tokenPresent) {
      await channel.postMessage(GITHUB_AUTHENTICATED);
      await channel.close();
    }
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setTag('reported_from', 'github-auth');
      Sentry.captureException(err);
    });
    toast.error(err.message, { id: 'github-auth-error' });
  }
};

const github = {
  createGithubChannel,
  handleGitHubAuthentication,
};

export default github;
