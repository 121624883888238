import { useEffect } from 'react';
import { useUserQuery } from '@queries/user';
import { useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

export function TeamIntercomConfig() {
  const { update } = useIntercom();
  const { teamUuid } = useParams();
  const { data } = useUserQuery({ suspense: true });

  useEffect(() => {
    if (!data) return;
    const team = data.teams.find((team) => team.uuid === teamUuid);
    if (!team) return;

    const teamMemberships = data?.teams.map((team) => team.name).join();

    update({
      name: data?.name,
      email: data?.email,
      customAttributes: {
        user_id: data?.id ?? null,
        team_memberships: teamMemberships ?? null,
        latest_active_team_name: team?.name ?? null,
      },
    });
  }, [data, teamUuid]);

  return null;
}
