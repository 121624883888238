import * as Yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import { useForm, useWatch, Controller } from 'react-hook-form';
import React from 'react';
import { replace } from 'ramda';
import { yupResolver } from '@hookform/resolvers/yup';

import useInputCache from '@hooks/useInputCache';
import { FormInput } from '@common/FormInput/FormInput';
import { FormSelect } from '@common/FormSelect/FormSelect';
import { FormDivider } from '@common/FormDivider/FormDivider';
import { FormCheckbox } from '@common/FormCheckbox/FormCheckbox';
import { IconSearch } from '@common/Icons/Icons';
import { IconGitHub } from '@common/Icons/Icons';
import { SelectCard } from '@common/SelectCard/SelectCard';
import transitions from '@utils/transitions';
import humanId from 'human-id';

import text from '@styles/Text.module.css';
import styles from './FunctionCreate.module.css';
import { Restricted } from '@components/Restricted';
import { DEV_OR_ADMIN } from '@utils/roles';
import { INSTALL_URL } from './FunctionGithubCreate';
import { GITHUB_INSTALLED, createGithubChannel } from '@utils/github';

const CreateButton = (props) => (
  <>
    <p className="pt-12 mb-16">
      <Restricted.Button
        roles={DEV_OR_ADMIN}
        type={props.type || 'primary'}
        className="w-100"
        loading={props.loading}
        onClick={props.onClickCreate}
        disabled={props.disabled}
      >
        {props.message}
      </Restricted.Button>
    </p>
    <p className="mb-0 text-center text-muted small">
      When you push to{' '}
      <strong>{props.currentRepo?.default_branch || 'GitHub'}</strong>, we will
      automatically deploy the Function.
    </p>
    <p className="mb-0 text-center text-muted small">
      Can’t see your repo here?{' '}
      <span onClick={props.onClickInstall} className={text.link}>
        Configure the Evervault app on Github.
      </span>
    </p>
  </>
);

const AccountsSelect = (props) => (
  <div>
    <FormSelect
      name={props.name || 'Account'}
      placeholder="Account"
      defaultValue={props.defaultValue || props.gitHubInstalls[0]}
      options={props.gitHubInstalls}
      isClearable={false}
      isSearchable={false}
      Icon={<IconGitHub />}
      loadingMessage={() => 'Loading GitHub accounts…'}
      onChange={(install) => props.onChangeInstall(install)}
      disabled={props.loading}
      isInlineLeft
      errors={props.errors}
    />
  </div>
);

const GithubAuthenticate = (props) => (
  <motion.div key="authenticate" {...transitions.collapsible}>
    <p>
      In order to ensure you can easily update your Function after deploying it,
      please connect it to a GitHub repository.
    </p>
    <p>
      <Restricted.Button
        roles={DEV_OR_ADMIN}
        type="github"
        className="w-100"
        Icon={<IconGitHub />}
        onClick={props.onClickAuth}
        loading={props.loading}
      >
        Authenticate with GitHub
      </Restricted.Button>
    </p>
    <p className="mb-0 text-muted text-center small">
      Don't use GitHub?{' '}
      <a href={'https://docs.evervault.com/reference/cli'}>
        Use our CLI instead!
      </a>
    </p>
  </motion.div>
);

const SelectPath = (props) => (
  <motion.div key="select-path" {...transitions.collapsible}>
    <div className="row">
      <div className="col-12 col-xs-6 mb-16">
        <SelectCard
          name="Import repository"
          description="Create Function from an existing GitHub repository"
          anySelected={props.path}
          selected={props.path === 'import'}
          onClick={() => props.onClickPath('import')}
        />
      </div>
      <div className="col-12 col-xs-6 mb-16">
        <SelectCard
          name="Choose template"
          description="Select one of our Function boilerplates to get started"
          anySelected={props.path}
          selected={props.path === 'template'}
          onClick={() => props.onClickPath('template')}
        />
      </div>
    </div>
  </motion.div>
);

const Templates = (props) => (
  <motion.div key="templates" {...transitions.collapsible}>
    <div className="row">
      {props.templates?.map((template, index) => (
        <div className="col-12 col-xs-6 mb-24" key={index}>
          <SelectCard
            name={template.name}
            description={template.description}
            accentColor={template.accentColor}
            Logo={template.Logo}
            anySelected={props.selectedTemplate}
            selected={template.repo === props.selectedTemplate?.repo}
            onClick={() => props.onClickSelectTemplate(template)}
          />
        </div>
      ))}
    </div>
  </motion.div>
);

const generateRandomTemplateName = (selectedTemplate) => {
  const randomName = humanId({ separator: '-', capitalize: false });
  return `${replace('template-', '', selectedTemplate.repo)}-${randomName}`;
};

const buildTemplateFunctionSchema = (
  existingFunctionNames,
  existingGithubRepos
) =>
  Yup.object({
    functionName: Yup.string()
      .matches(
        /^[A-Za-z0-9][-_\]?[A-Za-z0-9]*$/,
        'Function name contains invalid characters.'
      )
      .notOneOf(existingFunctionNames, 'Function names must be unique.')
      .notOneOf(
        existingGithubRepos,
        'Function names cannot be existing Github repositories'
      )
      // eslint-disable-next-line no-template-curly-in-string
      .min(2, 'Function names must be at least ${min} characters long')
      // eslint-disable-next-line no-template-curly-in-string
      .max(100, 'Function names must be less than ${max} characters long')
      .required('Function name is required.'),
    privateRepo: Yup.boolean().required(),
  });

const CreateFromTemplate = (props) => {
  const { register, handleSubmit, control, setValue, formState } = useForm({
    mode: 'onTouched',
    defaultValues: {
      functionName: '',
      privateRepo: true,
    },
    resolver: yupResolver(
      buildTemplateFunctionSchema(
        props.existingFunctionNames,
        props.existingGithubRepos
      )
    ),
  });
  const { errors } = formState;
  const submissionHandler = handleSubmit(props.onClickCreate);

  const watchedPrivateRepo = useWatch({ control, name: 'privateRepo' });

  React.useEffect(() => {
    if (props.selectedTemplate) {
      setValue(
        'functionName',
        generateRandomTemplateName(props.selectedTemplate),
        {
          shouldDirty: true,
        }
      );
    }
  }, [props.selectedTemplate, setValue]);

  return (
    <motion.div key="create-from-template" {...transitions.collapsible}>
      <form onSubmit={submissionHandler}>
        <div className={styles.formWrapper}>
          <AccountsSelect
            gitHubInstalls={props.gitHubInstalls}
            loading={props.loading}
            onChangeInstall={props.onChangeInstall}
            errors={errors}
          />
          <div>
            <FormInput
              name="functionName"
              inputProps={{
                placeholder: 'Enter your repository name…',
                disabled: props.loading,
                'data-prefix': true,
                ...register('functionName'),
              }}
              error={errors?.functionName}
            />
          </div>
        </div>
        <div>
          <Controller
            control={control}
            name={'privateRepo'}
            render={({ field: { ref, onChange, name } }) => (
              <FormCheckbox
                label={<span className="text-muted">Private Repository</span>}
                name={name}
                disabled={props.loading}
                ref={ref}
                onChange={(e) => onChange(e.target.checked)}
                checked={!!watchedPrivateRepo}
              />
            )}
          />
        </div>
        <CreateButton
          message="Deploy Function and Create Repository"
          loading={props.loading}
          disabled={!props.selectedInstall}
          onClickCreate={submissionHandler}
          onClickInstall={props.onClickInstall}
        />
      </form>
    </motion.div>
  );
};

const computeImportedFunctionName = (selectedRepo, subdir) => {
  if (selectedRepo) {
    let cleanedSubdirectory = subdir;
    if (subdir) {
      if (subdir.startsWith('/')) {
        cleanedSubdirectory = subdir.replace(/^\/+/, '');
      }
      if (cleanedSubdirectory.endsWith('/')) {
        cleanedSubdirectory = cleanedSubdirectory.replace(/\/+$/, '');
      }
    }
    cleanedSubdirectory = cleanedSubdirectory?.replace(/\//g, '-');
    return `${selectedRepo?.label}${
      cleanedSubdirectory ? `-${cleanedSubdirectory}` : ''
    }`;
  }
};

const buildImportFunctionSchema = (existingFunctionNames) =>
  Yup.object({
    isSubdirectoryDeployment: Yup.boolean(),
    subdirectory: Yup.string().when(
      'isSubdirectoryDeployment',
      (isSubdirectoryDeployment, schema) =>
        isSubdirectoryDeployment
          ? schema
              .matches(/^\/?([A-z0-9-_+]*\/?)+$/, {
                message: 'The subdirectory must be a valid file path.',
              })
              .test({
                name: 'uniqueSubdirFunctionName',
                test: function (value) {
                  const selectedRepo = this.parent.selectedRepo;
                  const newFunctionName = computeImportedFunctionName(
                    selectedRepo,
                    value
                  );
                  const exists =
                    existingFunctionNames.includes(newFunctionName);
                  return !exists;
                },
                message: 'Function names must be unique.',
              })
              .required('The subdirectory is required.')
          : schema
    ),
    selectedRepo: Yup.object().required('Repo to import is required.'),
  });

const CreateFromImport = (props) => {
  const repoOptions = React.useMemo(
    () => props.repos?.map((repo) => ({ label: repo.name, value: repo.id })),
    [props.repos]
  );

  const reposLoading = React.useMemo(
    () => props.reposLoading,
    [props.reposLoading]
  );

  const { register, handleSubmit, setValue, control, formState } = useForm({
    mode: 'onTouched',
    defaultValues: {
      selectedRepo: {},
      isSubdirectoryDeployment: false,
    },
    // resolver: yupResolver(
    //   buildImportFunctionSchema(props.existingFunctionNames)
    // ),
  });

  const values = useWatch({
    control,
    defaultValue: false,
  });
  const { errors, isSubmitting } = formState;

  const submissionHandler = handleSubmit(props.onClickCreate);

  useInputCache(values, 'isSubdirectoryDeployment', 'subdirectory', setValue);

  const [computedFunctionName, setComputedFunctionName] = React.useState('');

  React.useEffect(() => {
    const newFunctionName = computeImportedFunctionName(
      values.selectedRepo,
      values.subdirectory
    );
    if (!!newFunctionName && newFunctionName !== computedFunctionName) {
      setComputedFunctionName(newFunctionName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedRepo, values.subdirectory]);

  return (
    <motion.div key="create-from-import" {...transitions.collapsible}>
      <form onSubmit={submissionHandler}>
        <div className={styles.formWrapper}>
          <AccountsSelect
            gitHubInstalls={props.gitHubInstalls}
            loading={props.loading}
            onChangeInstall={props.onChangeInstall}
          />
          <div className="mb-8">
            <Controller
              control={control}
              name="selectedRepo"
              render={({ field: { ref, onChange, name } }) => (
                <FormSelect
                  name={name}
                  placeholder="Choose your Function repository…"
                  options={repoOptions}
                  isClearable={false}
                  isSearchable
                  isInlineRight
                  isLoading={reposLoading}
                  Icon={<IconSearch />}
                  loadingMessage={() => 'Loading repositories…'}
                  noOptionsMessage={() => 'No repositories found.'}
                  onChange={(repo) => onChange(repo)}
                  ref={ref}
                  disabled={props.loading || isSubmitting}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-n8">
          <Controller
            control={control}
            name="isSubdirectoryDeployment"
            render={({ field: { ref, onChange, name } }) => (
              <FormCheckbox
                label={
                  <span className="text-muted">Deploy from Subdirectory</span>
                }
                name={name}
                disabled={props.loading || isSubmitting}
                ref={ref}
                checked={!!values.isSubdirectoryDeployment}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        </div>
        <AnimatePresence initial={false}>
          {values.isSubdirectoryDeployment && (
            <motion.div key="subdirectory-wrapper" {...transitions.collapsible}>
              <FormInput
                name="subdirectory"
                error={errors?.subdirectory}
                helperText={
                  <>
                    Your Function will be called <b>{computedFunctionName}</b>
                  </>
                }
                inputProps={{
                  placeholder: 'e.g. function/hello-function',
                  disabled: props.loading || isSubmitting,
                  ...register('subdirectory'),
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <CreateButton
          message="Deploy Function"
          loading={props.loading}
          onClickCreate={submissionHandler}
          currentRepo={props.currentRepo}
          onClickInstall={props.onClickInstall}
          disabled={
            !values.selectedRepo ||
            !props.selectedInstall ||
            Object.keys(errors).length > 0
          }
        />
      </form>
    </motion.div>
  );
};

export const FunctionCreate = ({
  onClickPath,
  onClickAuth,
  onClickInstall,
  onClickSelectTemplate,
  onClickCreate,
  onChangeInstall,
  onChangeRepo,
  gitHubUser,
  gitHubInstalls,
  path,
  selectedTemplate,
  currentRepo,
  setFunctionName,
  repositories,
  templates,
  loading,
  functionName,
  selectedInstall,
  selectedRepo,
  existingFunctionNames,
  repos,
  reposLoading,
}) => {
  const isGitHubAuthenticated = gitHubUser && repos !== undefined;
  const isPathAny = !!path;
  const isPathImport = path === 'import';
  const isPathTemplate = path === 'template';
  const isTemplateSelected = selectedTemplate;

  const handleClickGithubAuth = () => {
    if (gitHubUser && repos === undefined) {
      const channel = createGithubChannel();
      const installWindow = window.open(
        INSTALL_URL,
        'installEvervaultWindow',
        'height=700, width=800'
      );

      channel.onmessage = (msg) => {
        if (msg === GITHUB_INSTALLED) {
          installWindow.close();
        }
      };
      return;
    }

    onClickAuth();
  };

  return (
    <>
      <p>
        A secure, serverless function for computing your most sensitive data.
      </p>
      <AnimatePresence initial={false}>
        {!isTemplateSelected && (
          <SelectPath
            key="select-path"
            onClickPath={onClickPath}
            path={path}
            selectedTemplate={selectedTemplate}
          />
        )}

        {isPathAny && (
          <FormDivider
            key="divider-1"
            title={selectedTemplate ? '← Go back' : null}
            onClick={
              selectedTemplate ? () => onClickSelectTemplate(null) : null
            }
          />
        )}

        {isPathAny && !isGitHubAuthenticated && !selectedTemplate && (
          <GithubAuthenticate
            key="github-authenticate"
            onClickAuth={handleClickGithubAuth}
            loading={loading}
          />
        )}

        {isPathImport && isGitHubAuthenticated && (
          <CreateFromImport
            key="create-from-import"
            onClickInstall={onClickInstall}
            onClickCreate={onClickCreate}
            onChangeInstall={onChangeInstall}
            onChangeRepo={onChangeRepo}
            gitHubInstalls={gitHubInstalls}
            repositories={repositories}
            loading={loading}
            currentRepo={currentRepo}
            selectedInstall={selectedInstall}
            selectedRepo={selectedRepo}
            existingFunctionNames={existingFunctionNames}
            repos={repos}
            reposLoading={reposLoading}
          />
        )}

        {isPathTemplate && (isGitHubAuthenticated || selectedTemplate) && (
          <Templates
            key="templates"
            onClickSelectTemplate={onClickSelectTemplate}
            selectedTemplate={selectedTemplate}
            templates={templates}
          />
        )}

        {isTemplateSelected && <FormDivider key="divider-2" />}

        {isTemplateSelected && (
          <CreateFromTemplate
            key="create-from-template"
            onChangeInstall={onChangeInstall}
            loading={loading}
            gitHubInstalls={gitHubInstalls}
            onClickCreate={onClickCreate}
            functionName={functionName}
            setFunctionName={setFunctionName}
            onClickInstall={onClickInstall}
            selectedInstall={selectedInstall}
            selectedTemplate={selectedTemplate}
            existingFunctionNames={existingFunctionNames}
            existingGithubRepos={repos?.map((repo) => repo.name) || []}
          />
        )}
      </AnimatePresence>
    </>
  );
};
