import { useNavigate } from 'react-router-dom';
import styles from './ChartCard.module.css';
import { ReactNode } from 'react';
import { Data, SimpleLine } from './SimpleLine';
import format from '@utils/format';

type ChartCardProps = {
  to?: string;
  name: string;
  text: ReactNode;
  icon?: ReactNode;
  status?: ReactNode | ReactNode[];
  data: Data;
};

export const ChartCard = ({
  name,
  text,
  data,
  to,
  icon,
  status,
}: ChartCardProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!to) return;
    navigate(to);
  };

  return (
    <div
      className={styles.container}
      onClick={handleClick}
      data-clickable={Boolean(to)}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {status && <div className={styles.status}>{status}</div>}
      <div className={styles.meta}>
        <h5 className={styles.name}>{name}</h5>
        <p className={styles.text}>{format.capitalizeFirstLetter(text)}</p>
      </div>
      <div className={styles.usage}>
        <SimpleLine data={data} />
      </div>
    </div>
  );
};
