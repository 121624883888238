import { useUserQuery } from '@queries/user';
import { Navigate, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import Layout from '@components/Layout';

export const PREVIOUS_KEY = 'previous';

export function useStoredTeamAndApp() {
  const { data } = useUserQuery();
  const { teamUuid, appUuid } = useParams();

  useEffect(() => {
    const team = (data?.teams || []).find((team) => team.uuid === teamUuid);
    const app = (team?.apps || []).find((app) => app.uuid === appUuid);

    if (team && app) {
      window.localStorage.setItem(
        PREVIOUS_KEY,
        JSON.stringify({ teamUuid: team.uuid, appUuid: app.uuid })
      );
    }
  }, [data, teamUuid, appUuid]);
}

export const RedirectToFirstTeam = () => {
  const { data } = useUserQuery();

  const to = useMemo(() => {
    const previous = window.localStorage.getItem(PREVIOUS_KEY);

    if (previous && data) {
      const { teamUuid, appUuid } = JSON.parse(previous);
      const team = (data?.teams || []).find((team) => team.uuid === teamUuid);
      const app = (team?.apps || []).find((app) => app.uuid === appUuid);
      if (app) return `/${teamUuid}/${appUuid}`;
    }

    const firstTeam = (data?.teams || [])[0];
    if (!firstTeam) return null;

    return `/${firstTeam?.uuid}`;
  }, [data]);

  return to ? <Navigate to={to} replace /> : <Layout.Loader />;
};
