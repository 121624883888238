import { createElement } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from '@common/Button/Button';
import Modal from '@common/Modal/Modal';
import { userQuery } from '@queries/user';

export const AppError = ({
  onRetry,
  children,
  buttonIcon,
  buttonLabel = 'Try again',
  title = 'Something went wrong.',
}) => {
  return (
    <>
      <h6 className="mb-0 color-secondary-60">Oops!</h6>
      <h3 className="mb-12">{title}</h3>
      <p className="mb-32">{children}</p>

      <Button onClick={onRetry} className="d-inline-block">
        {buttonIcon && createElement(buttonIcon)}
        {buttonLabel}
      </Button>
    </>
  );
};

export const AppErrorModal = () => {
  const queryClient = useQueryClient();

  const handleReset = () => queryClient.resetQueries(userQuery.queryKey);

  return (
    <Modal key="error" hideCloseButton disableEscapeKeyDown>
      <AppError buttonLabel="Take me home" onRetry={handleReset}>
        We’ve been notified and are working to get things back to normal
        quickly. <em>Hang in there!</em>
      </AppError>
    </Modal>
  );
};
