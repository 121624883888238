import { useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';

type InfiniteScrollProps = {
  fetchNextPage: () => void;
};

export function InfiniteScroll({ fetchNextPage }: InfiniteScrollProps) {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) fetchNextPage();
  }, [isInView, fetchNextPage]);

  return <div ref={ref} />;
}
