/**
 *
 * @returns {boolean}
 */
export const hasEnclaveLimits = (team) =>
  team.enclaveUsageLimits ? true : false;

/**
 *
 * @returns {string}
 */
export const admin = () => 'admin';

/**
 *
 * @returns {string}
 */
export const adminProductMetrics = () => `${admin()}/product-metrics`;

/**
 * @returns {string}
 */
export const adminTeams = () => `${admin()}/teams`;

/**
 * @returns {string}
 */
export const adminTeam = (teamUuid) => `${adminTeams()}/${teamUuid}`;

/**
 * @returns {string}
 */
export const adminTeamPayments = (teamUuid) =>
  `${adminTeam(teamUuid)}/payments`;

/**
 * @returns {string}
 */
export const adminAppPciMode = (teamUuid, appUuid) =>
  `${adminTeam(teamUuid)}/apps/${appUuid}/pci-mode`;

/**
 * @returns {string}
 */
export const adminTeamEnclaveLimits = (teamUuid) =>
  `${adminTeam(teamUuid)}/enclaves/limits`;

/**
 * @returns {string}
 */
export const adminTeamEnclaveInfo = (teamUuid) =>
  `${adminTeam(teamUuid)}/enclaves`;

export const adminTeamResourceUsageLimits = (teamUuid) =>
  `${adminTeam(teamUuid)}/resource-usage-limits`;
