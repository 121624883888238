import * as Primitive from '@radix-ui/react-select';
import { SelectLabelProps } from '@radix-ui/react-select';
import css from './styles.module.css';
import { forwardRef } from 'react';

export const SelectLabel = forwardRef<HTMLDivElement, SelectLabelProps>(
  function SelectLabel({ className, ...props }, ref) {
    const classes = [css.label, className].filter(Boolean).join(' ');

    return <Primitive.Label {...props} className={classes} ref={ref} />;
  }
);
