import { ElementType } from 'react';
import { Text, TextProps } from '.';
import css from './styles.module.css';
import { cn } from '@vault/utilities';

type TextHeadingProps<T extends ElementType> = Omit<TextProps<T>, 'size'> & {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
};

export function TextHeading<T extends ElementType = 'h3'>({
  as,
  className,
  ...props
}: TextHeadingProps<T>) {
  const classes = cn(css.heading, className);
  return <Text as="h3" className={classes} {...props} />;
}
