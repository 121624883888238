import React from 'react';

const defaultOptions = {
  defaultStatus: false,
  shouldValidate: true,
  shouldDirty: true,
};

/**
 * Custom helper hook for react-hook-form to persist unmounting field's value
 * @param values - from useWatch. Current values of the form fields.
 * @param causeField - name of the field which will cause the unmount (e.g. checkbox toggle for fields)
 * @param effectField - field which will unmount
 * @param setValueFn - callback function to set the cached value in the form state
 * @param options
 * @param options.defaultStatus - initial mount status of the effect field
 * @param options.shouldValidate - should setting the cached value into the input cause a validation event
 * @param options.shouldDirty - should setting the cached value into the input update the form touched values
 */
export default function useInputCache(
  values,
  causeField,
  effectField,
  setValueFn,
  options = defaultOptions
) {
  const resolveFn = (vals, state) => {
    // If cause field is truthy in form values (naive implementation for checkbox)
    const isMounted = !!vals[causeField];
    return {
      isMounted,
      mountStatusChanged: state !== isMounted,
      hasValue: vals[effectField] !== undefined, // field will become undefined when unmounted
    };
  };

  const [effectCache, setEffectCache] = React.useState(values[effectField]);
  const currentState = React.useRef(options.defaultStatus);

  React.useEffect(() => {
    const { isMounted, mountStatusChanged, hasValue } = resolveFn(
      values,
      currentState.current
    );

    if (mountStatusChanged) {
      if (isMounted && hasValue) {
        // field has mounted and value is available to be set, if cache is truthy
        // then set cached value and acknowledge mounting event
        if (!!effectCache) {
          setValueFn(effectField, effectCache, {
            shouldValidate: options.shouldValidate,
            shouldDirty: options.shouldDirty,
          });
        }
        currentState.current = !!isMounted;
      } else if (!isMounted && hasValue) {
        // field has unmounted and value is still present
        // store value in cache and acknowledge unmount event
        setEffectCache(values[effectField]);
        currentState.current = !!isMounted;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, causeField, effectField, resolveFn, setValueFn]);
}
