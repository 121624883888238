import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode } from 'react';
import css from './Drawer.module.css';

type DrawerTitleProps = {
  children: ReactNode;
};

export default function DrawerTitle({ children }: DrawerTitleProps) {
  return <Dialog.Title className={css.title}>{children}</Dialog.Title>;
}
