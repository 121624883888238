import { forwardRef } from 'react';
import css from './styles.module.css';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export const MenuLabel = forwardRef<
  HTMLDivElement,
  DropdownMenu.DropdownMenuLabelProps
>(function MenuLabel({ className, ...props }, ref) {
  const classes = [css.menuLabel, className].filter(Boolean).join(' ');

  return <DropdownMenu.Label ref={ref} className={classes} {...props} />;
});
