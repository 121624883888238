import { ReactNode } from 'react';
import css from './Drawer.module.css';

type DrawerFooterProps = {
  children: ReactNode;
  className?: string;
};

export default function DrawerFooter({
  children,
  className,
}: DrawerFooterProps) {
  const classes = [css.footer, className].filter(Boolean).join(' ');
  return <div className={classes}>{children}</div>;
}
