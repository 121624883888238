import * as Sentry from '@sentry/react';
import { getLoginRedirect, setLoginRedirect } from './loginRedirectStorage';
import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useCurrentTeam, useUserQuery } from '@queries/user';
import NotFound from '@components/NotFound/NotFound';
import Layout from '@components/Layout';
import { useAuth0 } from '@hooks/useAuth0';

// Makes a call to the API to check if the user is logged in. This will suspend to the
// nearest <Suspense> boundary while the request is in progress.
export function RequireAuth({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isAuthenticated && !isLoading) {
    setLoginRedirect(location);
    return <Navigate to="/login" replace />;
  }

  return children;
}

export function RequireAuthOutlet() {
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  );
}

export function RequireAdmin({ children }: { children: React.ReactNode }) {
  const { data } = useUserQuery({ suspense: true });

  const isSuperUser = data?.roleName === 'superuser';

  if (!isSuperUser) {
    return <Navigate to="/" replace />;
  }

  return children;
}

// The opposite to <RequireAuth />. This will redirect to the root path if the user is
// logged in. This makes an API call and will suspend to the nearest <Suspense> boundary
// while the request is in progress.
export function RequireNotAuthed({ children }: { children: React.ReactNode }) {
  const { isAuthenticated } = useAuth0();
  const to = getLoginRedirect() || '/';

  if (isAuthenticated) {
    console.log('redireting to from RequireNotAuthed', to);
    setLoginRedirect(null);
    return <Navigate to={to} replace />;
  }
  return children;
}

export function RequireNotAuthedOutlet() {
  return (
    <RequireNotAuthed>
      <Outlet />
    </RequireNotAuthed>
  );
}

export function RequireTeamAccess({ children }: { children: React.ReactNode }) {
  const { loading, team } = useCurrentTeam();

  if (loading) return <Layout.Loader />;
  if (!team) return <NotFound />;

  return children;
}
