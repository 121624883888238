import { Auth0ContextInterface, useAuth0 as useAuth } from '@auth0/auth0-react';

const mockAuth0 = {
  isAuthenticated: () => true,
  logout: () => {},
  loginWithRedirect: () => {},
  getAccessTokenSilently: () => 'token',
} as unknown as Auth0ContextInterface<{}>;

export const useAuth0 = () => {
  return import.meta.env.VITE_IS_TEST ? mockAuth0 : useAuth();
};
