import { compose, join, over, lensIndex, toUpper } from 'ramda';

const toTitleCase = compose(join(''), over(lensIndex(0), toUpper));

const url = (url) => {
  const regex =
    /^((\w+):)?((?:\/\/)?((\w+)?(:(\w+))?@)?([^/?:]+)(:(\d+))?)?(\/?([^/?#][^?#]*)?)?(\?([^#]+))?(#(\w*))?/;
  const formattedUrl = url.match(regex);

  return {
    url: formattedUrl[0],
    protocol: formattedUrl[2],
    username: formattedUrl[5],
    password: formattedUrl[7],
    hostname: formattedUrl[8] || '',
    port: formattedUrl[10],
    pathname: formattedUrl[11] || '',
    querystring: formattedUrl[14] || '',
    fragment: formattedUrl[16] || '',
  };
};

const snakeToTitleCase = (snakeString) => {
  if (!snakeString) return snakeString;

  return snakeString
    .split('_')
    .map((x) => {
      if (x.length > 0) {
        return x[0].toUpperCase() + x.slice(1);
      } else {
        return x;
      }
    })
    .join(' ');
};

const prettyJSON = (json) => {
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch (_) {
    return string;
  }
};

// TODO: Remove this function and replace usage with prettyJSON.
// This function is used by old relay logs which expect a JSON object
// with a value key.
const prettify = (string) => {
  try {
    return JSON.stringify(JSON.parse(string).value, null, 2);
  } catch (_) {
    return string;
  }
};

const list = (array = []) => {
  const formatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  });

  if (array) {
    return formatter.format(array);
  }
};

/**
 * Build url query params from an object of k\v.
 * Ignores keys with null or undefined values.
 * @param {string} url
 * @param {Object} queryParams
 * @returns {string} URL query params
 */
const urlQueryParams = (url, queryParams) => {
  let query = '';
  Object.keys(queryParams).forEach((param, index) => {
    if (
      queryParams[param] !== null &&
      queryParams[param] !== undefined &&
      queryParams[param] !== ''
    ) {
      const queryParam = `${param}=${queryParams[param]}`;
      if (index === 0) {
        query += queryParam;
      } else {
        query += `&${queryParam}`;
      }
    }
  });
  return query === '' ? url : `${url}?${query}`;
};

const toCurrency = (value, currency = 'usd') =>
  value.toLocaleString('en-US', { style: 'currency', currency: currency });

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string[0].toUpperCase() + string.slice(1);
};

const prettyXML = (input) => {
  try {
    const parser = new DOMParser();
    const xml = parser.parseFromString(input, 'application/xml');
    const xsltString = [
      '<xsl:stylesheet version="1.0"',
      ' xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      ' <xsl:output method="xml" indent="yes"/>',
      ' <xsl:template match="@*|node()">',
      ' <xsl:copy>',
      ' <xsl:apply-templates select="@*|node()"/>',
      ' </xsl:copy>',
      ' </xsl:template>',
      '</xsl:stylesheet>',
    ].join('\n');
    const xslt = parser.parseFromString(xsltString, 'application/xml');
    const processor = new XSLTProcessor();
    processor.importStylesheet(xslt);
    const output = processor.transformToDocument(xml);
    return new XMLSerializer().serializeToString(output);
  } catch (_) {
    return input;
  }
};

const format = {
  list,
  prettify,
  prettyJSON,
  prettyXML,
  snakeToTitleCase,
  toTitleCase,
  url,
  urlQueryParams,
  toCurrency,
  capitalizeFirstLetter,
};

export default format;
