import { Location } from 'react-router-dom';
import { z } from 'zod';

export const loginRedirectStorageKey = 'beforeLogin' as const;

const loginRedirectStorageSchema = z.object({
  pathname: z.string(),
  search: z.string().optional(),
  hash: z.string().optional(),
});

export type LoginRedirectStorage = z.infer<typeof loginRedirectStorageSchema>;

export function getLoginRedirect(): LoginRedirectStorage | null {
  const data = localStorage.getItem(loginRedirectStorageKey);
  if (data === null) {
    return null;
  }

  try {
    return loginRedirectStorageSchema.parse(JSON.parse(data));
  } catch (error) {
    clearLoginRedirect();
    return null;
  }
}

export function setLoginRedirect(location: Location | null) {
  if (location) {
    localStorage.setItem(
      loginRedirectStorageKey,
      JSON.stringify({
        pathname: location.pathname,
        search: location.search,
        hash: location.hash,
      })
    );
  } else {
    localStorage.removeItem(loginRedirectStorageKey);
  }
}

export function clearLoginRedirect(): void {
  localStorage.removeItem(loginRedirectStorageKey);
}
