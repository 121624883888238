import 'react-app-polyfill/stable';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import environments from '@utils/environments';

import { RequestMocks } from './mocks';

window.requestMocks = new RequestMocks();

// Use resize oberserver polyfill to support older versions of safari
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

// Clear obsolete local storage items
// You can remove this after May 2023
window.localStorage.removeItem('refresh-token');
window.localStorage.removeItem('active-team-id');

// Don’t track analytics on dev environments
if (!environments.isProduction) {
  window.localStorage.setItem('plausible_ignore', Boolean(true).toString());
}

// Sate the codemirror gods
if (window.global == null) {
  // @ts-ignore
  window.global = {};
}

const container = document.getElementById('root');

if (!container) {
  throw new Error('Could not find root element');
}

const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
