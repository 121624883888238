import Prism from 'prismjs';
(typeof global !== 'undefined' ? global : window).Prism = Prism;
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-xml-doc';

import Highlight, { Language, defaultProps } from 'prism-react-renderer';
import css from './styles.module.css';
import { light } from './themes';

type CodeProps = {
  children: string;
  language: Language | 'xml';
  className?: string;
  lineNumbers?: boolean;
};

export function Code({
  children,
  language,
  className,
  lineNumbers,
}: CodeProps) {
  const classes = [css.code, className].filter(Boolean).join(' ');

  return (
    <Highlight
      {...defaultProps}
      code={children.trim()}
      theme={light}
      Prism={Prism}
      language={language}
    >
      {({ style, tokens, getLineProps, getTokenProps }) => (
        <pre className={classes} style={style}>
          <div className={css.content}>
            {lineNumbers && (
              <div className={css.gutter}>
                {tokens.map((line, i) => {
                  const props = getLineProps({
                    line,
                    className: css.gutterline,
                  });

                  return (
                    <div key={i} {...props}>
                      <span key={i} className={css.lineNumber}>
                        {i + 1}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
            <div className={css.lines}>
              {tokens.map((line, i) => {
                const props = getLineProps({ line });

                return (
                  <div key={i} className={props.className} style={props.style}>
                    {line.map((token, key) => {
                      const tokenProps = getTokenProps({ token });
                      return (
                        <span
                          key={key}
                          className={tokenProps.className}
                          style={tokenProps.style}
                        >
                          {token.content}
                        </span>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </pre>
      )}
    </Highlight>
  );
}
