import css from './styles.module.css';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type MenuHeadingProps = {
  children: React.ReactNode;
};

export function MenuHeading({ children }: MenuHeadingProps) {
  return (
    <DropdownMenu.Label className={css.heading}>{children}</DropdownMenu.Label>
  );
}
