import css from './Nodes.module.css';
import { Terminal } from 'lucide-react';
import { motion } from 'framer-motion';

export default function DiagramService({
  payload,
  glow,
  icon = <Terminal />,
  children,
  data,
}) {
  return (
    <motion.div
      transition={{ duration: 0.2 }}
      initial={{ scale: 0.8 }}
      animate={{ scale: 1 }}
      className={css.service}
      data-glow={glow}
    >
      <div className={css.serviceLabel}>
        <div className={css.nodeIcon}>{icon}</div>
        <div className={css.nodeName}>{children}</div>
      </div>
      {data}
      {payload && (
        <div className={css.payload}>
          {`{"`}
          <span className={css.payloadKey}>{payload.key}</span>
          {`":"`}
          <span className={css.payloadValue} data-highlight={payload.highlight}>
            {payload.value}
          </span>
          {`"}`}
        </div>
      )}
    </motion.div>
  );
}
