import * as Primitive from '@radix-ui/react-select';
import { forwardRef } from 'react';
import css from './styles.module.css';
import { Check } from 'lucide-react';

type SelectOptionProps = Primitive.SelectItemProps;

const SelectOption = forwardRef<HTMLDivElement, SelectOptionProps>(
  function SelectOption({ children, value, className, ...props }, ref) {
    const classes = [css.item, className].filter(Boolean).join(' ');

    return (
      <Primitive.Item ref={ref} className={classes} value={value} {...props}>
        <Primitive.ItemText asChild>
          <span className={css.itemText}>{children}</span>
        </Primitive.ItemText>
        <Primitive.ItemIndicator>
          <Check className={css.check} />
        </Primitive.ItemIndicator>
      </Primitive.Item>
    );
  }
);

export default SelectOption;
