import { lazy } from 'react';

const EXPIRATION_KEY = '_lazy_import_expiration';
const ERROR_EXPIRATION_TIME = 60000; // 1 min

function shouldRefresh() {
  if (import.meta.env.DEV) return false;
  const expiration = localStorage.getItem(EXPIRATION_KEY);
  if (!expiration) return true;
  const isExpired = new Date().getTime() > expiration;
  return isExpired;
}

function setRefreshExpiration() {
  const now = new Date().getTime();
  localStorage.setItem(EXPIRATION_KEY, now + ERROR_EXPIRATION_TIME);
}

export function handleFailedLazy(lazyfn) {
  function handleError(err) {
    if (shouldRefresh()) {
      setRefreshExpiration();
      window.location.reload();
    } else {
      throw err;
    }
  }

  return lazyfn().catch(handleError);
}

export function lazyRoute(importfn) {
  return lazy(() => {
    return handleFailedLazy(importfn);
  });
}
