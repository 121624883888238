import { Canvas } from '@react-three/fiber';
import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import {
  BufferAttribute,
  BufferGeometry,
  Color,
  Points,
  ShaderMaterial,
} from 'three';

const vertexShader = `
  varying vec3 vColor;
  varying float vAlpha;
  attribute float alpha;
  uniform float uTime;
  uniform vec3 uColor;

  void main() {
    vColor = uColor;
    vec3 pos = position;
    vAlpha = alpha;
    gl_PointSize = 6.0;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;

const fragmentShader = `
  varying vec3 vColor;
  uniform float uTime;
  varying float vAlpha;

  void main() {
    float maxOpacity = 0.5;
    float speed = 0.5;
    float a = mod(vAlpha + (uTime * speed), maxOpacity);
    vec4 color = vec4(vColor, a);
    gl_FragColor = color;
  }
`;

const MovingSquaresShader = () => {
  const particlesRef = useRef<Points>(null);
  const materialRef = useRef<ShaderMaterial>(null);
  const geometryRef = useRef<BufferGeometry>(null);

  const gridDimensions = { width: 200, height: 300 };
  const gridSize = gridDimensions.width * gridDimensions.height;
  const squareSize = 0.08;

  const positions = useMemo(() => {
    const posArray = new Float32Array(gridSize * 3);
    const alphaArray = new Float32Array(gridSize);

    let index = 0;
    for (let i = 0; i < gridDimensions.width; i++) {
      for (let j = 0; j < gridDimensions.height; j++) {
        const posX = i * squareSize - gridDimensions.width * squareSize * 0.5;
        const posY = j * squareSize - gridDimensions.height * squareSize * 0.5;
        posArray[index * 3] = posX;
        posArray[index * 3 + 1] = posY;
        posArray[index * 3 + 2] = 0;

        alphaArray[index] = Math.random() * Math.PI * 2;
        index++;
      }
    }

    return [posArray, alphaArray];
  }, [gridDimensions, gridSize, squareSize]);

  useEffect(() => {
    geometryRef.current?.setAttribute(
      'position',
      new BufferAttribute(positions[0], 3)
    );
    geometryRef.current?.setAttribute(
      'alpha',
      new BufferAttribute(positions[1], 1)
    );
  }, [positions]);

  useFrame(({ clock }) => {
    if (!materialRef.current) return;
    materialRef.current.uniforms.uTime.value = clock.getElapsedTime();
  });

  return (
    <points ref={particlesRef}>
      <bufferGeometry ref={geometryRef} attach="geometry"></bufferGeometry>
      <shaderMaterial
        ref={materialRef}
        attach="material"
        args={[
          {
            vertexShader,
            fragmentShader,
            uniforms: {
              uTime: { value: 0 },
              uColor: { value: new Color('#E1D6FF') },
            },
            transparent: true,
            depthWrite: false,
          },
        ]}
        vertexColors
      />
    </points>
  );
};

export default function Pixels() {
  return (
    <Canvas
      dpr={Math.max(window.devicePixelRatio, 2)}
      camera={{ position: [0, 0, 5] }}
    >
      <MovingSquaresShader />
      <ambientLight intensity={5} />
    </Canvas>
  );
}
