const ls = window.localStorage;

const LocalStorageService = {
  getItem: (key) => ls.getItem(key),
  setItem: (key, val) => ls.setItem(key, val),
  removeItem: (key) => ls.removeItem(key),
  hasOwnProperty: (key) => ls.hasOwnProperty(key),
};

export default LocalStorageService;
