import Tippy from '@tippyjs/react';
import cn from 'classnames';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-toward-subtle.css';

// Documentation for Tippy
// https://atomiks.github.io/tippyjs/

export const Tooltip = ({
  content,
  children,
  className = '',
  theme = 'dark',
  ...props
}) =>
  content ? (
    <Tippy
      content={content}
      animation="shift-toward-subtle"
      arrow={false}
      touch={false}
      theme={theme}
      {...props}
    >
      <span
        tabIndex={0}
        className={cn('d-inline-flex align-self-center', className)}
      >
        {children}
      </span>
    </Tippy>
  ) : (
    <span
      tabIndex={0}
      className={cn('d-inline-flex align-self-center', className)}
    >
      {children}
    </span>
  );
