import { AnimatePresence } from 'framer-motion';
import cn from 'classnames';

import { Button } from '@common/Button/Button';
import { FormHelperText } from '@common/FormHelperText/FormHelperText';
import { IconLock, IconUpload } from '@common/Icons/Icons';
import { Tooltip } from '@common/Tooltip/Tooltip';

import form from '@styles/Forms.module.css';
import styles from './FormInput.module.css';

export const FormInput = ({
  label,
  labelCta,
  name,
  type = 'text',
  error,
  helperText,
  prefix = false,
  suffix = false,
  Icon = undefined,
  showErrorMessage = true,
  className,
  fileName,
  inputProps,
  autoFocus,
  actions = [],
}) => (
  <div className={cn(form.group, className)}>
    <div className={styles.labelContainer}>
      {label && (
        <label className={form.label} htmlFor={name}>
          {label}
        </label>
      )}
      {labelCta && labelCta}
    </div>
    <div className="position-relative d-flex align-items-stretch flex-wrap">
      {Boolean(prefix) && (
        <div className={cn(form.textField, styles.prefix)} data-error={!!error}>
          {prefix}
        </div>
      )}
      {Icon && <div className={form.inputIcon}>{Icon}</div>}
      <input
        name={name}
        id={name}
        type={type}
        className={cn(form.textField, Boolean(Icon) && 'pl-40')}
        data-error={!!error}
        data-prefix={Boolean(prefix)}
        data-suffix={Boolean(suffix || actions.length > 0)}
        autoFocus={Boolean(autoFocus)}
        {...inputProps}
      />
      {Boolean(inputProps?.readOnly && inputProps.disabled) && (
        <Tooltip content="You can’t edit this field">
          <span className={styles.readOnly}>
            <span className="d-flex align-items-center">
              <IconLock />
            </span>
          </span>
        </Tooltip>
      )}
      {type === 'file' && (
        <>
          <div
            className={cn(
              form.textField,
              form.uploadField,
              fileName && 'text-body'
            )}
            data-placeholder={fileName || inputProps?.placeholder}
          />
          <Button
            className="pointer-events-none"
            type="outlined"
            size="sm"
            isInline
          >
            <IconUpload className="m-0" />
          </Button>
        </>
      )}
      {Boolean(suffix) && (
        <div className={cn(styles.prefix, styles.suffix)} data-error={!!error}>
          {suffix}
        </div>
      )}
      {actions
        .filter(Boolean)
        .map(({ className, tooltip, ...action }, index) => {
          return (
            <Tooltip
              key={index}
              content={tooltip}
              className={styles.actionTooltip}
            >
              <button
                className={cn(
                  form.textField,
                  styles.prefix,
                  styles.suffix,
                  styles.action,
                  className
                )}
                type="button"
                {...action}
              />
            </Tooltip>
          );
        })}
    </div>
    <AnimatePresence initial={false}>
      {showErrorMessage && (!!error || Boolean(helperText)) && (
        <FormHelperText key={`${name}-helper-text`} data-error={!!error}>
          {error?.message ? error.message : helperText}
        </FormHelperText>
      )}
    </AnimatePresence>
  </div>
);
