import styles from './styles.module.css';
import { motion } from 'framer-motion';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronRight } from 'lucide-react';

type MenuSubMenuProps = DropdownMenu.DropdownMenuSubContentProps & {
  label: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
};

export function MenuSubMenu({
  label,
  icon,
  children,
  ...props
}: MenuSubMenuProps) {
  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className={styles.menuItem}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <span className={styles.label}>{label}</span>
        <ChevronRight className={styles.subMenuArrow} />
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent
          className={styles.menu}
          sideOffset={8}
          alignOffset={-4}
          {...props}
          asChild
        >
          <motion.div {...ANIMATIONS}>{children}</motion.div>
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
}

const ANIMATIONS = {
  initial: {
    opacity: 0,
    x: -5,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  transition: {
    duration: 0.2,
  },
};
