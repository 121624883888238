import { ReactNode } from 'react';
import css from './Layout.module.css';

const LayoutLoader = ({ children, ...props }: { children?: ReactNode }) => (
  <div className={css.loader}>
    <svg
      width={60}
      height={66}
      fill="none"
      className={css.loaderLogo}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.34 8.558A4.495 4.495 0 0 0 .016 12.12 5.75 5.75 0 0 1 0 11.778V26.27c.002-.095.007-.188.015-.282a5.722 5.722 0 0 0 3.926 5.014l2.695.883-4.295 2.344a4.495 4.495 0 0 0-2.326 3.564A5.754 5.754 0 0 1 0 37.45v14.492c.002-.095.007-.189.015-.282a5.723 5.723 0 0 0 3.926 5.014L25.61 63.77a28.906 28.906 0 0 0 22.846-2.098l9.204-5.024a4.495 4.495 0 0 0 2.326-3.564c.008.114.013.228.015.343V38.936a4.462 4.462 0 0 1-.015.282 5.723 5.723 0 0 0-3.926-5.015l-2.695-.882 4.295-2.344a4.495 4.495 0 0 0 2.326-3.564c.008.113.013.228.015.343V13.264a4.46 4.46 0 0 1-.015.282 5.723 5.723 0 0 0-3.926-5.014L34.39 1.436a28.907 28.907 0 0 0-22.846 2.098L2.341 8.558ZM8.973 32.65 3.2 35.8a2.704 2.704 0 0 0-1.408 2.296v.41c.033 2.182 1.521 4.103 3.706 4.783l1.218.378 9.237-5.042a16.818 16.818 0 0 1 5.56-1.87L8.971 32.65Zm26.715 6.864A28.901 28.901 0 0 0 48.455 36l2.751-1.501 4.295 1.407a3.932 3.932 0 0 1 2.708 3.669v.259c-.034 1.435-.873 2.744-2.2 3.432l-3.433 1.779-16.889-5.531Zm-9.816-15.562L9.096 18.737l7.714-4.21a15.031 15.031 0 0 1 11.88-1.092l21.657 7.093-2.994 1.552c-6.582 3.41-14.356 4.088-21.482 1.872Zm3.377-12.219 23.328 7.64 3.432-1.778c1.328-.688 2.167-1.997 2.201-3.433v-.26a3.932 3.932 0 0 0-2.708-3.668L33.834 3.138a27.116 27.116 0 0 0-21.43 1.968L3.198 10.13a2.704 2.704 0 0 0-1.408 2.296v.41c.033 2.181 1.521 4.103 3.706 4.782l1.218.378 9.237-5.042a16.822 16.822 0 0 1 13.296-1.22Zm-3.377 37.89L9.096 44.41l7.714-4.211a15.031 15.031 0 0 1 11.88-1.09L50.347 46.2l-2.994 1.551c-6.582 3.41-14.356 4.088-21.482 1.873Z"
        fill="currentColor"
      />
    </svg>
    {children}
  </div>
);

export default LayoutLoader;
