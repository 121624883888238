import { team } from './teams';

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const apps = (teamUuid) => `${team(teamUuid)}/apps`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const app = (teamUuid, appUuid) => `${apps(teamUuid)}/${appUuid}`;
