import { app } from './apps';

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const appGithub = (teamUuid, appUuid) =>
  `${app(teamUuid, appUuid)}/github`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const appGithubInstalls = (teamUuid, appUuid) =>
  `${appGithub(teamUuid, appUuid)}/installs`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const appGithubRepos = (teamUuid, appUuid) =>
  `${appGithub(teamUuid, appUuid)}/repos`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const appGithubLogin = (teamUuid, appUuid) =>
  `${appGithub(teamUuid, appUuid)}/login`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const appGithubAuthenticate = (teamUuid, appUuid) =>
  `${appGithub(teamUuid, appUuid)}/authenticate`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const appGithubRepoStatus = (teamUuid, appUuid) =>
  `${appGithub(teamUuid, appUuid)}/repo/status`;
