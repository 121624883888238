import css from './Drawer.module.css';
import { Button } from '@vault';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

export default function DrawerClose() {
  return (
    <Dialog.Close asChild>
      <Button className={css.close} size="xs">
        <X />
      </Button>
    </Dialog.Close>
  );
}
