import React, { ReactNode } from 'react';
import PrimaryCard from './PrimaryCard/PrimaryCard';
import SecondaryCard from './SecondaryCard/SecondaryCard';
import styles from './EmptyState.module.css';

interface EmptyStateProps {
  children: ReactNode;
  title: string;
  description: string;
  asset: ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> & {
  PrimaryCard: typeof PrimaryCard;
  SecondaryCard: typeof SecondaryCard;
} = ({ children, title, description, asset }) => {
  return (
    <div className={styles.container}>
      <div className={styles.children}>
        <h5 className="mb-4">{title}</h5>
        <p>{description}</p>
        <div className={styles.cards}>{children}</div>
      </div>
      <div className={styles.asset}>{asset}</div>
    </div>
  );
};

EmptyState.PrimaryCard = PrimaryCard;
EmptyState.SecondaryCard = SecondaryCard;

export default EmptyState;
