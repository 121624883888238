export const light = {
  plain: {
    fontSize: 12,
    lineHeight: 1.8,
    color: 'var(--grey-70)',
  },
  styles: [
    {
      types: ['string', 'number', 'boolean', 'attr-value'],
      style: {
        color: '#1B998B',
      },
    },
    {
      types: ['class-name', 'function', 'script', 'tag'],
      style: {
        color: 'var(--primary)',
      },
    },
    {
      types: ['keyword'],
      style: {
        color: '#D83E7C',
      },
    },
    {
      types: ['punctuation'],
      style: {
        color: 'var(--grey-50)',
      },
    },
    {
      types: ['comment', 'operator'],
      style: {
        color: 'var(--grey-50)',
      },
    },
    {
      types: ['property', 'attr-name'],
      style: {
        color: 'var(--grey-60)',
      },
    },
    {
      types: ['attr-name'],
      style: {
        color: 'var(--grey-70)',
      },
    },
  ],
};
