/**
 * @returns {string}
 */
export const users = () => `users`;

/**
 * @param {string} userId
 * @returns {string}
 */
export const user = (userId) => `${users()}/${userId}`;

/**
 * @param {string} userId
 * @returns {string}
 */
export const userTeams = (userId) => `${user(userId)}/teams`;

/**
 * @returns {string}
 */
export const usersOnboardRequests = () => `${users()}/onboard-requests`;

/**
 * @returns {string}
 */
export const usersOnboard = () => `${users()}/onboard`;

/**
 * @param {string} requestUuid
 * @returns {string}
 */
export const userOnboardRequest = (requestUuid) =>
  `${usersOnboardRequests()}/${requestUuid}`;

export const userChangePassword = (userId) => `${user(userId)}/changePassword`;
