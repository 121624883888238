import { accessTokenStore, getAccessToken } from '@state/authInfo';
import ky from 'ky';

const API_PREFIX =
  import.meta.env.VITE_EV_API_URL ??
  'https://internal-api-evervault-com.relay.evervault.com';

const DIRECT_API_PREFIX =
  import.meta.env.VITE_EV_DIRECT_API_URL ??
  'https://internal-api.evervault.com';

export const apiCreate = (prefixUrl) =>
  ky.create({
    prefixUrl,
    retry: {
      limit: 0,
    },
    timeout: 20000,
    credentials: 'include',
    hooks: {
      // Set auth header from local storage every request
      beforeRequest: [
        async (request) => {
          const accessToken = await accessTokenStore.getToken();
          // Return 401 response immedialty without network request
          if (accessToken == null) {
            return new Response(JSON.stringify({ error: 'no_token' }), {
              status: 401,
            });
          }

          // workaround for request cloning issue in chromium for tests
          // https://github.com/microsoft/playwright/issues/6479
          if (window.localStorage.getItem('DISABLE_KY_CLONE') === 'true') {
            request.clone = () => request;
          }

          request.headers.set('Authorization', `Bearer ${accessToken}`);
          return request;
        },
      ],
      // Handle auth errors on 401/403
      afterResponse: [
        async (request, _options, response) => {
          const status = response.status;
          if (status === 401 || status === 403) {
            const body = await response.json();

            if (
              body.error &&
              (body.error === 'mfa_required' || body.error === 'no_token')
            ) {
              // Auth is totally invalid; trigger logout
              await accessTokenStore.logout();
            }

            return response;
          }
        },
      ],
    },
  });

/**
 * Instance of Ky set up with Evervault API Relay Domain as the prefix
 */
export const api = apiCreate(API_PREFIX);

/**
 * Instance of Ky set up with Evervault Direct API Domain as the prefix
 */
export const directApi = apiCreate(DIRECT_API_PREFIX);

/**
 * Shorthand function to reduce verbosity
 * @param {string} origin
 * @returns
 */
export function handleResponseOptions(origin) {
  return {
    hooks: {
      beforeError: [(err) => handleError(origin, err)],
    },
  };
}

/**
 *
 * @param {string} origin
 * @param {import('ky').HTTPError} error
 */
function handleError(origin, error) {
  const response = error.response;
  const code = response.status || response.status === 0 ? response.status : '';
  const title = response.statusText || '';
  const status = `${code} ${title}`.trim();
  error.message = `Response error occurred in ${origin} (status: ${
    status ? status : 'unknown'
  }, request_id: ${response.headers.get('x-request-id')})`;

  console.warn(error.message);

  return error;
}
