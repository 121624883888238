import React from 'react';

function Check() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="7"
      fill="none"
      viewBox="0 0 8 7"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M1 4.5L2.5 6s2-3 4.5-4.5"
      ></path>
    </svg>
  );
}

export default Check;
