import Diagram from '@common/Diagram';
import Evervault from '@common/Diagram/Evervault';
import useMediaQuery from '@hooks/useMediaQuery';
import { Cog, Terminal } from 'lucide-react';
import { motion } from 'framer-motion';
import styles from './EmptyStateDiagram.module.css';

export default function EmptyStateDiagram() {
  const isDesktop = useMediaQuery('(min-width: 1000px)');
  return (
    <Diagram direction={isDesktop ? 'horizontal' : 'vertical'}>
      <Diagram.Nodes>
        <Diagram.Group>
          <Diagram.Node id="server-request">
            <Diagram.Service
              icon={<Terminal />}
              payload={{
                key: 'email',
                value: 'ev:Xv4e',
                highlight: true,
              }}
            >
              Server
            </Diagram.Service>
          </Diagram.Node>
        </Diagram.Group>

        <Diagram.Group>
          <div className={styles.functions}>
            <motion.div
              className={styles.functionCall}
              transition={{
                duration: 1,
                delay: 1.5,
                repeatDelay: 3,
                repeat: Infinity,
                ease: 'easeInOut',
              }}
              animate={{
                y: [20, 0, 0, 0],
                opacity: [0, 1, 1, 0],
                rotate: [0, 0, 180, 180],
                scale: [0.6, 1, 1, 0.6],
              }}
            >
              <Cog />
            </motion.div>
            <Diagram.Node id="functions">
              <Diagram.Service icon={<Evervault />} glow>
                Functions
              </Diagram.Service>
            </Diagram.Node>
          </div>
        </Diagram.Group>

        <Diagram.Group>
          <Diagram.Node id="server-response">
            <Diagram.Service
              icon={<Terminal />}
              payload={{
                key: 'isValid',
                value: 'true',
              }}
            >
              Server
            </Diagram.Service>
          </Diagram.Node>
        </Diagram.Group>
      </Diagram.Nodes>

      <Diagram.Connection nodes={['server-request', 'functions']}>
        <Diagram.Request delay={0} duration={1.5} repeatDelay={2.5} />
      </Diagram.Connection>
      <Diagram.Connection nodes={['functions', 'server-response']}>
        <Diagram.Request
          encrypted={true}
          duration={1.5}
          repeatDelay={2.5}
          ease="easeOut"
          delay={3}
        />
      </Diagram.Connection>
    </Diagram>
  );
}
