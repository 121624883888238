import { Link, NavLink, useParams } from 'react-router-dom';
import { appRoutes } from '../config';

import styles from './SecondaryNav.module.css';
import Logo from '../Logo';
import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCurrentApp } from '@queries/user';
import { motion } from 'framer-motion';
import SkeletonText from '@common/SkeletonText';

const THRESHOLD = 70;
export default function SecondaryNav() {
  const flags = useFlags();
  const { app, loading } = useCurrentApp();
  const { teamUuid, appUuid } = useParams();
  const [isStuck, setIsStuck] = useState(false);
  const routes = appRoutes(teamUuid, appUuid);
  const filteredRoutes = routes.filter((route) => {
    if (route.disabled !== undefined) {
      return !route.disabled(flags);
    }

    if (!route.requiredFlag) {
      return route;
    } else if (flags[route.requiredFlag]) {
      return route;
    }
  });

  useEffect(() => {
    const onScroll = () => {
      setIsStuck(window.scrollY > THRESHOLD);
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return (
      <nav className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.logo} data-sticky={isStuck}>
            <Link to={teamUuid ? `/${teamUuid}` : '/'} onClick={scrollToTop}>
              <Logo />
            </Link>
          </div>
          <div className={styles.navList} data-sticky={isStuck}>
            <div className={styles.skeletons}>
              <SkeletonText width={80} height={20} />
              <SkeletonText width={100} height={20} />
              <SkeletonText width={90} height={20} />
              <SkeletonText width={70} height={20} />
              <SkeletonText width={80} height={20} />
              <SkeletonText width={90} height={20} />
            </div>
          </div>
        </div>
      </nav>
    );
  }

  if (!app) return null;

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      className={styles.container}
    >
      <div className={styles.wrapper}>
        <div className={styles.logo} data-sticky={isStuck}>
          <Link to={teamUuid ? `/${teamUuid}` : '/'} onClick={scrollToTop}>
            <Logo />
          </Link>
        </div>
        <div className={styles.navList} data-sticky={isStuck}>
          {filteredRoutes.map(({ title, route, end, notification }) => (
            <NavLink
              key={route}
              to={route}
              end={end}
              data-notification={notification}
              onClick={scrollToTop}
              className={styles.navLink}
            >
              {title}
            </NavLink>
          ))}
        </div>
      </div>
    </motion.nav>
  );
}
