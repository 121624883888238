import { ReactNode } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import './styles.css';

type ScrollAreaProps = {
  children: ReactNode | ReactNode[];
};

export function ScrollArea({ children }: ScrollAreaProps) {
  return (
    <OverlayScrollbarsComponent
      defer
      style={{ height: '100%', width: '100%' }}
      options={{ scrollbars: { autoHide: 'scroll' } }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
}
