import css from './styles.module.css';
import { FlexItem } from './FlexItem';
import { ComponentPropsWithoutRef } from 'react';

type FlexProps = ComponentPropsWithoutRef<'div'> & {
  gap?: string;
  align?: 'start' | 'center' | 'end';
  justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
};

export function Flex({
  gap,
  children,
  className,
  direction,
  align,
  justify,
  ...props
}: FlexProps) {
  const classes = [css.flex, className].filter(Boolean).join(' ');
  return (
    <div
      {...props}
      className={classes}
      data-align={align}
      data-justify={justify}
      data-direction={direction}
      style={{ gap }}
    >
      {children}
    </div>
  );
}

Flex.Item = FlexItem;
