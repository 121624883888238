import css from './styles.module.css';
import { ComponentPropsWithoutRef } from 'react';
import { ConnectFieldButton } from './ConnectedFieldButton';

type ConnectFieldsProps = ComponentPropsWithoutRef<'div'> & {
  children: React.ReactNode | React.ReactNode[];
};

export function ConnectedFields({
  children,
  className,
  ...props
}: ConnectFieldsProps) {
  const classes = [css.fields, className].filter(Boolean).join(' ');

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
}

ConnectedFields.Button = ConnectFieldButton;
