import * as RadixTabs from '@radix-ui/react-tabs';
import { motion } from 'framer-motion';

export type TabProps = {
  children: React.ReactNode;
  label: string;
  className?: string;
};

export function Tab({ children, label, className }: TabProps) {
  return (
    <RadixTabs.Content key={label} value={label} asChild>
      <motion.div {...TAB_ANIMATIONS} className={className}>
        {children}
      </motion.div>
    </RadixTabs.Content>
  );
}

const TAB_ANIMATIONS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 },
};
