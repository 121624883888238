import React from 'react';
import cn from 'classnames';

import styles from './Alert.module.css';

export default function Alert({
  children,
  className,
  title,
  icon,
  severity,
  size = '',
  isModal,
}) {
  return (
    <div
      data-has-title={title ? true : false}
      className={cn(
        styles.alert,
        isModal && styles.alertModal,
        severity && styles[severity],
        size && styles[size],
        className
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <div>
        {title && <h4 className={styles.title}>{title}</h4>}
        {children}
      </div>
    </div>
  );
}
