import { Link, useParams } from 'react-router-dom';
import Logo from '../Logo';
import css from './styles.module.css';
import UserSwitcher from './UserSwitcher';
import { UpgradeAction } from './UpgradeAction';

type LayoutHeaderProps = {
  children?: React.ReactNode;
};

export default function LayoutHeader({ children }: LayoutHeaderProps) {
  const { teamUuid } = useParams();
  const logoLink = teamUuid ? `/${teamUuid}` : '/';

  return (
    <nav className={css.container}>
      <div className={css.wrapper}>
        <div className={css.leftNav}>
          <Link to={logoLink}>
            <Logo />
          </Link>
          {children}
        </div>
        <div className={css.rightNav}>
          <div className={css.desktopOnly}>
            <a
              className={css.docs}
              href="https://docs.evervault.com"
              target="_blank"
            >
              Docs
            </a>
            <UpgradeAction />
          </div>
          <UserSwitcher />
        </div>
      </div>
    </nav>
  );
}
