import { UpgradeButton } from '@common/UpgradeButton';
import { useCurrentTeam } from '@queries/user';

export function UpgradeAction() {
  const { team } = useCurrentTeam();

  if (team?.tier !== 'free') return null;

  return <UpgradeButton teamUuid={team.uuid} />;
}
