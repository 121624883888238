import classNames from 'classnames';
import styles from './styles.module.css';

export default function SkeletonText({ width, height, className }) {
  return (
    <div
      className={classNames(styles.skeleton, className)}
      style={{ width, height }}
    />
  );
}
