import cn from 'classnames';
import { includes } from 'ramda';
import { motion } from 'framer-motion';

import { Spinner } from '@common/Spinner/Spinner';

import styles from './Button.module.css';
import { forwardRef } from 'react';

const VARIANTS = {
  'icon-dark': cn(styles.icon, styles.iconDark),
};
const variantClasses = (variant) => {
  return VARIANTS[variant] || styles[variant];
};

export const Button = forwardRef(
  (
    {
      children,
      type = 'primary',
      variant,
      size = '',
      isInline = false,
      loading = false,
      disabled = false,
      Icon = null,
      className,
      as,
      ...props
    },
    ref
  ) => {
    const Component = as ? as : motion.button;

    return (
      <Component
        ref={ref}
        className={cn(
          styles.button,
          type && styles[type],
          variant && variantClasses(variant),
          size && styles[size],
          isInline && styles.inline,
          className
        )}
        disabled={disabled || loading}
        data-inline={isInline}
        data-loading={loading}
        type={variant && type}
        {...props}
      >
        {loading && (
          <Spinner
            className="position-absolute pointer-events-none"
            initial={false}
            animate={
              loading ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 1.04 }
            }
            size={
              ['sm', 'md'].includes(size)
                ? '16px'
                : size === 'xs'
                ? '12px'
                : '24px'
            }
            color={
              includes(type, ['primary', 'secondary', 'danger', 'github'])
                ? 'var(--surface)'
                : 'var(--primary-100)'
            }
          />
        )}
        <motion.span
          className="d-flex align-items-center"
          initial={false}
          animate={
            loading ? { opacity: 0, scale: 1.04 } : { opacity: 1, scale: 1 }
          }
        >
          {Icon && Icon}
          {children}
        </motion.span>
      </Component>
    );
  }
);
