import { AggregationsReponsesType } from '@components/Logs/types';
import { directApi } from '@services/apiService';
import { useQuery } from '@tanstack/react-query';
import { getTime, sub } from 'date-fns';
import { useParams } from 'react-router-dom';

export function useResourceVolumne(id: string) {
  const { appUuid } = useParams();

  return useQuery({
    retry: false,
    initialData: { data: [] },
    queryKey: ['resource', id, 'volume'],
    select: ({ data }) => {
      return data.map((d) => ({
        x: d.x,
        y: d.metrics.reduce((acc, m) => acc + m.value, 0),
      }));
    },
    queryFn: async () => {
      return directApi
        .post('activity/aggregations', {
          json: {
            query: `evervault.resource.uuid = "${id}"`,
            metric: 'volume',
            range: {
              startTime: getTime(sub(new Date(), { days: 1 })),
              endTime: getTime(new Date()),
            },
          },
          headers: {
            'x-evervault-app-id': appUuid,
          },
        })
        .json<AggregationsReponsesType>();
    },
  });
}
