import { HttpResponse, http } from 'msw';
import { setupWorker } from 'msw/browser';

export class RequestMocks {
  constructor() {
    const storedMocks = localStorage.getItem('mocks');
    const mocks = storedMocks ? JSON.parse(storedMocks) : [];
    this.mocks = mocks;
    this.start();
  }

  get handlers() {
    return this.mocks.reverse().map((mock) => {
      return http[mock.method](mock.url, async (info) => {
        if (mock.delay) {
          await new Promise((resolve) => setTimeout(resolve, mock.delay));
        }
        return HttpResponse.json(mock.response);
      });
    });
  }

  start() {
    if (this.mocks.length === 0) return;
    const worker = setupWorker(...this.handlers);
    worker.start();
  }

  add(method, url, response, delay = 0) {
    const storedMocks = localStorage.getItem('mocks');
    const mocks = storedMocks ? JSON.parse(storedMocks) : [];

    mocks.push({ method, url, response, delay });

    localStorage.setItem('mocks', JSON.stringify(mocks));

    console.info(
      `Mock added for ${method.toUpperCase()} ${url}. Please refresh the page to see the effect`
    );
  }

  clear() {
    localStorage.removeItem('mocks');
    console.info('Mocks cleared. Please refresh the page.');
  }

  export() {
    const storedMocks = localStorage.getItem('mocks');
    const mocks = storedMocks ? JSON.parse(storedMocks) : [];
    const dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(mocks, null, 2));
    const dlAnchorElem = document.createElement('a');
    dlAnchorElem.setAttribute('href', dataStr);
    dlAnchorElem.setAttribute('download', 'mocks.json');
    dlAnchorElem.click();
  }

  import() {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (readerEvent) => {
        const content = readerEvent.target.result;
        localStorage.setItem('mocks', content);
        console.info('Mocks imported. Please refresh the page.');
      };
    };
    input.click();
  }
}
