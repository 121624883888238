import styles from './styles.module.css';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type MenuItemProps = DropdownMenu.DropdownMenuItemProps & {
  variant?: 'danger';
  icon?: React.ReactNode;
};

export function MenuItem({
  className,
  variant,
  children,
  icon,
  ...props
}: MenuItemProps) {
  const classes = [styles.menuItem, className].filter(Boolean).join(' ');

  return (
    <DropdownMenu.Item data-variant={variant} className={classes} {...props}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>{children}</span>
    </DropdownMenu.Item>
  );
}
