import { ComponentPropsWithoutRef } from 'react';
import css from './styles.module.css';

type FlexItemProps = ComponentPropsWithoutRef<'div'> & {
  grow?: boolean;
  shrink?: boolean;
};

export function FlexItem({
  children,
  className,
  grow,
  shrink,
  ...props
}: FlexItemProps) {
  const classes = [css.item, className].filter(Boolean).join(' ');

  return (
    <div className={classes} data-grow={grow} data-shrink={shrink} {...props}>
      {children}
    </div>
  );
}
