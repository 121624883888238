import React from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

import styles from './FormCheckbox.module.css';
import Check from './Check';

const boxVariants = {
  unchecked: {
    scaleX: 1,
    scaleY: 1,
  },
  checking: {
    scale: 0.9,
  },
};

const checkVariants = {
  unchecked: {
    scale: 0,
    opacity: 0,
  },
  checked: {
    scale: 1,
    opacity: 1,
  },
  indeterminate: {
    scale: 0,
    opacity: 0,
  },
  checking: {
    scale: 1,
    opacity: 0.8,
  },
};

const indeterminateVariants = {
  unchecked: {
    scale: 0,
    opacity: 0,
  },
  checked: {
    scale: 0,
    opacity: 0,
  },
  indeterminate: {
    scale: 1,
    opacity: 1,
  },
  checking: {
    scale: 0,
    opacity: 0,
  },
};

export const FormCheckbox = React.forwardRef(
  (
    {
      label,
      description = undefined,
      name,
      checked,
      disabled,
      onChange,
      size,
      className,
      indeterminate,
      ...props
    },
    ref
  ) => {
    let state = 'unchecked';
    if (indeterminate) state = 'indeterminate';
    if (checked) state = 'checked';

    return (
      <motion.label
        className={cn(styles.checkbox, className)}
        data-size={size}
        htmlFor={name}
        initial={false}
        animate={state}
        whileTap="checking"
        disabled={disabled}
      >
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          ref={ref}
          {...props}
        />
        <motion.span
          variants={boxVariants}
          data-state={state}
          data-size={size}
          className={styles.checkboxBox}
        >
          <motion.span className={styles.check} variants={checkVariants}>
            <Check />
          </motion.span>
          <motion.span
            className={styles.indeterminateIndicator}
            variants={indeterminateVariants}
          />
        </motion.span>
        <span className={size === 'sm' ? 'ml-4' : 'ml-8'}>
          {label}
          {description && (
            <span className="d-block text-muted small">{description}</span>
          )}
        </span>
      </motion.label>
    );
  }
);
