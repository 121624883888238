import { ComponentPropsWithoutRef } from 'react';
import css from './Drawer.module.css';

type DrawerContentProps = ComponentPropsWithoutRef<'div'>;

export default function DrawerContent({
  children,
  className,
  ...props
}: DrawerContentProps) {
  const classes = [css.scrollArea, className].filter(Boolean).join(' ');
  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
}
