import { api, handleResponseOptions } from '@services/apiService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { team as teamResource, teams } from '@utils/resources/teams';
import { USER_QUERY_KEY } from './user';
import { useParams } from 'react-router-dom';

// Scope Types

/**
 * @type {'scope/Team'}
 */
export const TEAM_SCOPE_TYPE = '__scope::Team';

/**
 * @type {'scope/TeamPermissions'}
 */
export const TEAM_PERMISSIONS_TYPE = '__scope::TeamPermissions';

// Query Definitions
export const teamQuery = (teamUuid) => ({
  queryKey: [{ scope: TEAM_SCOPE_TYPE, teamUuid }],
  queryFn: async () =>
    api
      .get(teamResource(teamUuid), {
        headers: {
          accept: 'application/json;version=2',
        },
        ...handleResponseOptions('teamQuery'),
      })
      .json(),
});

// Query Selectors
export const teamSelector = (teamData) => teamData.team;
export const accountStatusSelector = (teamData) =>
  teamData.team.accountStatus ? teamData.team.accountStatus : 'pending';
export const securityPageSelector = (teamData) => ({
  securityPage: teamData.team.securityPage,
  securityPageDomain: teamData.team.securityPageDomain ?? null,
});

export const hasPermissionsSelector =
  (resource, action) => (permissionsData) => {
    const lowerRes = resource.toLowerCase();
    const lowerAction = action.toLowerCase();
    if (
      Boolean(permissionsData) &&
      Boolean(permissionsData[lowerRes]) &&
      Boolean(permissionsData[lowerRes][lowerAction])
    ) {
      return permissionsData[lowerRes][lowerAction];
    }
    return false;
  };

// Query Hooks
export const useTeamQuery = (teamUuid, params) =>
  useQuery({
    ...teamQuery(teamUuid),
    ...params,
    select: params?.select ?? teamSelector,
    enabled: params?.enabled ?? true,
  });

// Mutation Definitions
const updateTeamMutation = (teamUuid) => ({
  mutationFn: async (params) =>
    api
      .put(teamResource(teamUuid), {
        ...handleResponseOptions('updateTeamMutation'),
        json: params,
      })
      .json(),
});

const deleteTeamMutation = (teamUuid) => ({
  mutationFn: (params) =>
    api
      .delete(teamResource(teamUuid), {
        ...handleResponseOptions('deleteTeamMutation'),
        json: params,
      })
      .json(),
});

// Mutation Hooks
export function useUpdateTeamMutation(teamUuid) {
  const queryClient = useQueryClient();

  const onSettled = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [{ teamUuid }] }),
      queryClient.invalidateQueries({ queryKey: USER_QUERY_KEY }),
    ]);
  };

  return useMutation({
    ...updateTeamMutation(teamUuid),
    onSettled,
  });
}

export function useDeleteTeamMutation(teamUuid) {
  const queryClient = useQueryClient();

  const onSettled = async () => {
    await Promise.all([
      queryClient.resetQueries({ queryKey: [{ teamUuid }] }),
      queryClient.invalidateQueries({ queryKey: USER_QUERY_KEY }),
    ]);
  };

  return useMutation({ ...deleteTeamMutation(teamUuid), onSettled });
}

export function useCreateTeam() {
  return useMutation({
    mutationFn: async ({ name }) => {
      return api
        .post(teams(), {
          ...handleResponseOptions('createTeam'),
          json: { name },
        })
        .json();
    },
  });
}
