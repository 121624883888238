import { descend, prop, sort } from 'ramda';

import { FunctionGithubCreate } from './FunctionCreate/FunctionGithubCreate';
import useModal from '@hooks/useModal';
import { useFunctionsQuery } from '@components/Functions/queries';

import styles from './FunctionsList.module.css';
import { FunctionCard } from './FunctionCard/FunctionCard';
import { accountStatusSelector, useTeamQuery } from '@queries/team';
import { SkeletonCards } from '@common/SkeletonCards/SkeletonCards';
import { useParams } from 'react-router-dom';
import { Restricted } from '@components/Restricted';
import { DEV_OR_ADMIN } from '@utils/roles';
import EmptyState from '@common/EmptyState';
import { PlayIcon, Rocket } from 'lucide-react';
import EmptyStateAsset from '@images/outbound-relay-empty-state.png';
import EmptyStateDiagram from './EmptyStateDiagram/EmptyStateDiagram';
import YouTubeEmbed from '@common/YouTubeEmbed/YouTubeEmbed';
import Heading from '@common/Heading';

const selectNewestFunctions = (functions) =>
  sort(descend(prop('createdAt')), functions);

export default function FunctionsList() {
  const { teamUuid } = useParams();
  const { data: accountStatus } = useTeamQuery(teamUuid, {
    suspense: true,
    select: accountStatusSelector,
  });
  const { openModal } = useModal();
  const { data: functionsData, isInitialLoading } = useFunctionsQuery({
    suspense: true,
    select: selectNewestFunctions,
  });

  const accountNotReady = accountStatus !== 'ready';

  const handleCreateFunction = () =>
    openModal(<FunctionGithubCreate />, {
      title: 'Create Function',
    });

  const handleWatchTutorial = () => {
    openModal(<YouTubeEmbed embedId="OzBjSwiasNw" />, { title: '' });
  };

  return (
    <>
      <Heading
        title="Functions"
        actions={
          <Restricted.Button
            roles={DEV_OR_ADMIN}
            data-testid="create-function"
            size="sm"
            onClick={handleCreateFunction}
            disabled={accountNotReady}
          >
            Create Function
          </Restricted.Button>
        }
      />

      {functionsData?.length === 0 && (
        <EmptyState
          asset={<EmptyStateDiagram />}
          title="Process your Encrypted Data"
          description="Secure serverless functions that allow you to run workflows, logic and processing on fully encrypted data, without exposing your infrastructure to sensitive information."
        >
          <EmptyState.PrimaryCard
            title="Follow a Getting Started tutorial in the Evervault Docs."
            href="https://docs.evervault.com/products/functions#getting-started"
            cta="Get Started"
            asset={EmptyStateAsset}
          />
          <EmptyState.SecondaryCard
            title="Follow a Guide"
            href="https://docs.evervault.com/guides/validate-phone-numbers"
            icon={<Rocket width={17} />}
          />
          <EmptyState.SecondaryCard
            title="Watch a Tutorial"
            onClick={handleWatchTutorial}
            icon={<PlayIcon width={17} />}
          />
        </EmptyState>
      )}
      <div className={styles.cards}>
        {isInitialLoading ? (
          <SkeletonCards instances={2} />
        ) : (
          functionsData?.map((func) => {
            return <FunctionCard key={func.uuid} func={func} />;
          })
        )}
      </div>
    </>
  );
}
