const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 60,
};

const scaleInUp = {
  initial: { width: 0, height: 0, scale: 0, opacity: 0 },
  animate: { width: 'auto', height: 'auto', scale: 1, opacity: 1 },
  exit: { width: 0, height: 0, scale: 0, opacity: 0 },
  transition: spring,
};

const scaleInSpring = {
  initial: { scale: 0, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0, opacity: 0 },
  transition: { type: 'spring', stiffness: 150 },
};

const fadeInOut = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: spring,
};

const fadeInUp = {
  initial: { y: 12, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -12, opacity: 0 },
  transition: spring,
};

const autoWidth = {
  initial: { width: 0, opacity: 0 },
  animate: { width: 'auto', opacity: 1 },
  exit: { width: 0, opacity: 0 },
};

const collapsible = {
  initial: { height: 0, opacity: 0, pointerEvents: 'none', overflow: 'hidden' },
  animate: {
    height: 'auto',
    opacity: 1,
    pointerEvents: 'auto',
    overflow: 'visible',
  },
  exit: {
    height: 0,
    opacity: 0,
    pointerEvents: 'none',
    overflow: 'hidden',
  },
  transition: spring,
};

const pulse = {
  initial: { opacity: 0 },
  animate: {
    opacity: [1, 0.4, 1],
    transition: {
      opacity: { repeat: Infinity, repeatType: 'loop', duration: 1.2 },
    },
  },
  exit: { opacity: 0 },
};

const pulseSlow = {
  initial: { opacity: 0 },
  animate: {
    opacity: [1, 0.6, 1],
    transition: {
      opacity: { repeat: Infinity, repeatType: 'loop', duration: 2.4 },
    },
  },
  exit: { opacity: 0 },
};

const transitions = {
  autoWidth,
  collapsible,
  fadeInOut,
  fadeInUp,
  pulse,
  pulseSlow,
  spring,
  scaleInUp,
  scaleInSpring,
};

export default transitions;
