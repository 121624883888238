import EmptyStateIllustration from '@images/empty-state-enclave-ip-address.svg?react';

export default function EmptyState() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center text-muted">
      <EmptyStateIllustration className="mt-16 mb-24" />
      <p className="w-70">
        Move encrypted data from a third party to another using
        <b className="color-secondary-60"> Workflows </b>.
      </p>
    </div>
  );
}
