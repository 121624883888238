import { Link, LinkProps } from 'react-router-dom';
import { MouseEvent } from 'react';

import { Button as CommonButton } from '@common/Button/Button';
import styles from './PrimaryCard.module.css';

interface PrimaryCardProps {
  title: string;
  asset: string;
  to?: LinkProps['to'];
  href?: string;
  cta?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export default function PrimaryCard({
  title,
  asset,
  href,
  to,
  cta,
  onClick,
}: PrimaryCardProps) {
  const Container = href ? 'a' : to ? Link : 'div';
  const Button: any = CommonButton;

  return (
    <Container
      className={styles.container}
      href={href}
      to={to!}
      target="_blank"
      onClick={onClick}
    >
      <div className={styles.containerInner}>
        <div className={styles.content}>
          <p className={styles.title}>{title}</p>
          {cta && (
            <Button size="xs" className="mt-8">
              {cta}
            </Button>
          )}
        </div>
        <div className={styles.asset}>
          <img src={asset} />
        </div>
      </div>
    </Container>
  );
}
