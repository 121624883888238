import cn from 'classnames';
import { toast } from 'react-hot-toast';
import { useIntercom } from 'react-use-intercom';
import { useUserQuery } from '@queries/user';

import styles from './UserSwitcher.module.css';
import { avatar } from '@utils/generateAvatar';
import { HeaderDropdown } from '../HeaderDropdown';
import { LifeBuoy, LogOut, Palette, ShieldCheck, UserCog } from 'lucide-react';
import SkeletonText from '@common/SkeletonText';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@hooks/useAuth0';

export default function UserSwitcher() {
  const location = useLocation();
  const { data: user, isInitialLoading } = useUserQuery();
  const { logout } = useAuth0();

  const { show } = useIntercom();

  if (isInitialLoading || !user) {
    return <SkeletonText width={30} height={30} />;
  }

  const signOut = async () => {
    await logout();
  };

  const orderedTeams = user.teams.sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : -1
  );
  const isSuperUser = user?.roleName === 'superuser';
  const primaryTeamUuid = orderedTeams[0].uuid;

  return (
    <div className={styles.container}>
      <HeaderDropdown>
        <HeaderDropdown.Trigger
          className={cn(styles.avatar, isSuperUser && styles.superUser)}
        >
          <img src={avatar(primaryTeamUuid)} />
        </HeaderDropdown.Trigger>
        <HeaderDropdown.Content align="end">
          <HeaderDropdown.Link
            prefix={<UserCog />}
            to="/settings"
            state={{ back: location }}
          >
            Account Settings
          </HeaderDropdown.Link>
          {isSuperUser && (
            <>
              <HeaderDropdown.Separator />
              <HeaderDropdown.Link
                prefix={<ShieldCheck />}
                to="/admin"
                state={{ back: location }}
              >
                Admin View
              </HeaderDropdown.Link>
              <HeaderDropdown.Link
                prefix={<Palette />}
                to="/vault"
                state={{ back: location }}
              >
                Vault Design Docs
              </HeaderDropdown.Link>
            </>
          )}
          <HeaderDropdown.Separator />
          <HeaderDropdown.Item prefix={<LifeBuoy />} onSelect={show}>
            Help & Support
          </HeaderDropdown.Item>
          <HeaderDropdown.Item prefix={<LogOut />} onSelect={signOut}>
            Sign Out
          </HeaderDropdown.Item>
        </HeaderDropdown.Content>
      </HeaderDropdown>
    </div>
  );
}
