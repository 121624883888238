import { useEffect, useMemo, useState } from 'react';

type DecryptTextProps = {
  children: string;
  speed?: number;
  trailSize?: number;
};

type DecryptionText = {
  decrypted: string;
  encrypted: string;
};

var CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+/=0123456789';

function randomChar(char: string): string {
  if (char === ' ') return char;
  return CHARS[Math.floor(Math.random() * CHARS.length)];
}

export function DecryptText({
  children,
  speed = 20,
  trailSize = 8,
}: DecryptTextProps) {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (index > children.length) {
        clearInterval(interval);
        return;
      }

      setIndex((i) => i + 1);
    }, speed);

    return () => clearInterval(interval);
  }, [index]);

  const text = useMemo<DecryptionText>(() => {
    const start = children.slice(0, Math.max(index, 0));
    const end = children
      .slice(index, index + trailSize)
      .split('')
      .map(randomChar)
      .join('');
    return { decrypted: start, encrypted: end };
  }, [children, index]);

  return (
    <>
      <span>{text.decrypted}</span>
      <span style={{ opacity: 0.5 }}>{text.encrypted}</span>
    </>
  );
}
