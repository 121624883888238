import { app } from './apps';

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const workflows = (teamUuid, appUuid) =>
  `${app(teamUuid, appUuid)}/workflows`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} workflowUuid
 * @returns {string}
 */
export const workflow = (teamUuid, appUuid, workflowUuid) =>
  `${workflows(teamUuid, appUuid)}/${workflowUuid}`;
