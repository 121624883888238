import css from './Dialog.module.css';
import { Dialog, DialogState, Button } from '@vault';
import * as Primitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

type DialogModalProps = {
  state: DialogState;
  title: string;
  children: React.ReactNode;
};

export function DialogModal({ state, title, children }: DialogModalProps) {
  return (
    <Dialog state={state}>
      <div className={css.header}>
        <Primitive.Title asChild>
          <h3>{title}</h3>
        </Primitive.Title>
      </div>
      <div className={css.body}>{children}</div>

      <Primitive.Close asChild>
        <Button size="xs" className={css.close}>
          <X />
        </Button>
      </Primitive.Close>
    </Dialog>
  );
}
