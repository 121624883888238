import { HTMLAttributes } from 'react';
import css from './styles.module.css';
import { cn } from '@vault';

export function CardSection({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn(css.section, className)} {...props}>
      {children}
    </div>
  );
}
