import { useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { USER_QUERY_KEY, useUserQuery, userTeamsSelector } from '@queries/user';
import { FormInput } from '@common/FormInput/FormInput';
import { Button } from '@common/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useDialogContext } from '@common/Dialog';
import { useCreateTeam } from '@queries/team';
import { useQueryClient } from '@tanstack/react-query';

const buildTeamCreateSchema = (teamNames) =>
  z.object({
    name: z
      .string()
      .trim()
      .min(2, { message: 'Team name must be at least 2 characters long' })
      .max(30, { message: "Team name can't be longer than 30 characters" })
      .regex(/^[a-zA-Z0-9\s\-_']+$/i, {
        message:
          "Team name can only contain alphanumeric characters, spaces, and ',.-",
      })
      .refine((value) => !teamNames.includes(value?.toUpperCase()), {
        message: 'Looks like you already have a team with that name',
      }),
  });

export const TeamCreate = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const { mutateAsync } = useCreateTeam();
  const dialog = useDialogContext();
  const userTeamsSub = useUserQuery({ select: userTeamsSelector });
  const teamNames = useMemo(
    () => (userTeamsSub.data ?? []).map((team) => team.name.toUpperCase()),
    [userTeamsSub.data]
  );

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    defaultValues: { name: '' },
    resolver: zodResolver(buildTeamCreateSchema(teamNames)),
  });

  const submissionHandler = async ({ name }) => {
    try {
      const { team } = await mutateAsync({ name });
      await client.invalidateQueries({ queryKey: USER_QUERY_KEY });
      await client.fetchQuery({ queryKey: USER_QUERY_KEY });
      dialog.close();
      navigate(`/${team.uuid}`);
    } catch (err) {
      toast.error('Error creating team.', { id: 'team-create-error' });
    }
  };

  return (
    <form onSubmit={handleSubmit(submissionHandler)}>
      <FormInput
        label="Team Name"
        name="name"
        error={errors?.name}
        className="mb-12"
        inputProps={{
          placeholder: 'e.g. PlanetExpress',
          disabled: isSubmitting,
          autoComplete: 'off',
          ...register('name'),
        }}
      />
      <Button disabled={!isValid} loading={isSubmitting}>
        Continue
      </Button>
    </form>
  );
};
