import { createContext, useContext } from 'react';

type TabsContextType = {
  value: string;
};

export const TabsContext = createContext<TabsContextType | undefined>(
  undefined
);

export function useTabs() {
  const ctx = useContext(TabsContext);
  if (!ctx) {
    throw new Error(
      'Tabs compound components cannot be rendered outside the Tabs component'
    );
  }

  return ctx;
}
