import { cn } from '@vault/utilities';
import css from './styles.module.css';
import { Spinner } from './Spinner';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

export type ButtonProps = ComponentPropsWithoutRef<'button'> & {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  loading?: boolean;
  variant?: 'primary' | 'secondary' | 'danger' | 'ghost';
  prefix?: React.ReactNode | Element;
  suffix?: React.ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      className,
      size = 'md',
      loading = false,
      variant = 'secondary',
      prefix,
      suffix,
      children,
      disabled,
      ...props
    },
    ref
  ) {
    const classes = cn(css.button, className);

    return (
      <button
        ref={ref}
        className={classes}
        data-size={size}
        data-variant={variant}
        data-loading={loading}
        disabled={disabled || loading}
        {...props}
      >
        {loading && (
          <span className={css.spinner}>
            <Spinner />
          </span>
        )}
        {prefix && <span className={css.prefix}>{prefix}</span>}
        <span className={css.label}>{children}</span>
        {suffix && <span className={css.suffix}>{suffix}</span>}
      </button>
    );
  }
);
