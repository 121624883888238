import { ShieldCheck } from 'lucide-react';
import css from './styles.module.css';
import { Link, Text, Tooltip } from '@vault';

export function PCIMode() {
  return (
    <Tooltip align="start" interactive content={<PCITooltip />}>
      <div className={css.pciMode}>
        <ShieldCheck />
        PCI Mode
      </div>
    </Tooltip>
  );
}

function PCITooltip() {
  return (
    <>
      <Text size="sm" weight={500} className="mb-2">
        PCI Mode
      </Text>
      <Text className="mb-2" size="sm" leading="md">
        This app is in PCI Mode. Apps in PCI Mode have additional security
        features and restrictions in place.
      </Text>
      <Link
        external
        href="https://docs.evervault.com/payments/overview/pci-dss#pci-restricted-apps"
      >
        Learn More {'->'}
      </Link>
    </>
  );
}
