import { ReactNode } from 'react';
import css from './styles.module.css';

type HeadingProps = {
  title: string;
  icon?: ReactNode;
  text?: ReactNode;
  className?: string;
  actions?: ReactNode | ReactNode[];
  variant?: 'danger';
};

export default function Heading({
  icon,
  title,
  className,
  text,
  actions,
  variant,
}: HeadingProps) {
  const classes = [css.heading, className].filter(Boolean).join(' ');

  return (
    <div className={classes} data-variant={variant}>
      <div data-testid="heading" className={css.content}>
        {icon && <div className={css.icon}>{icon}</div>}
        <h3 className={css.title}>{title}</h3>
        {text ? <p className={css.text}>{text}</p> : null}
      </div>
      {actions ? <div className={css.actions}>{actions}</div> : null}
    </div>
  );
}
