import { cn } from '@vault';
import { HTMLAttributes } from 'react';
import css from './Dialog.module.css';

export function DialogSection({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn(css.section, className)} {...props}>
      {children}
    </div>
  );
}
