import { useState } from 'react';
import css from './styles.module.css';
import * as Primitive from '@radix-ui/react-tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from '@vault/utilities';

type TooltipProps = {
  content: React.ReactNode;
  children: React.ReactNode;
  interactive?: boolean;
  className?: string;
  side?: Primitive.TooltipContentProps['side'];
  align?: Primitive.TooltipContentProps['align'];
  sideOffset?: Primitive.TooltipContentProps['sideOffset'];
  alignOffset?: Primitive.TooltipContentProps['alignOffset'];
};

export function Tooltip({
  content,
  children,
  interactive = false,
  side = 'bottom',
  align = 'center',
  sideOffset = 8,
  alignOffset = -8,
  className,
}: TooltipProps) {
  const [open, setOpen] = useState(false);
  const triggerClasses = cn(css.trigger, className);

  if (!content) {
    return children;
  }

  return (
    <Primitive.Root
      open={open}
      onOpenChange={setOpen}
      delayDuration={0}
      disableHoverableContent={!interactive}
    >
      <Primitive.Trigger asChild>
        <div className={triggerClasses}>{children}</div>
      </Primitive.Trigger>
      <Primitive.Portal forceMount>
        <AnimatePresence>
          {open && (
            <Primitive.Content
              side={side}
              align={align}
              sideOffset={sideOffset}
              alignOffset={alignOffset}
              asChild
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
                className={css.content}
                data-side={side}
                data-align={align}
              >
                {content}
              </motion.div>
            </Primitive.Content>
          )}
        </AnimatePresence>
      </Primitive.Portal>
    </Primitive.Root>
  );
}
