import { useCurrentTeam } from '@queries/user';
import { useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

export default function useEventTracking() {
  const { team } = useCurrentTeam();
  const { appUuid } = useParams();
  const { trackEvent } = useIntercom();

  return (eventName, eventProps) => {
    if (window.plausible) {
      const props = {
        team: team?.name,
        teamId: team?.uuid,
        appId: appUuid,
        ...(eventProps || {}),
      };

      window.plausible(eventName, props);
    }

    trackEvent(eventName, eventProps);
  };
}
