export const ADMIN = 1;
export const DEVELOPER = 2;
export const READ_ONLY = 3;
export const DEV_OR_ADMIN = [DEVELOPER, ADMIN];

export const USER_ROLES = [
  { value: ADMIN, label: 'Admin' },
  { value: DEVELOPER, label: 'Developer' },
  { value: READ_ONLY, label: 'Read Only' },
];

export function labelForRole(id) {
  const role = USER_ROLES.find((r) => r.value === id);
  return role?.label;
}
