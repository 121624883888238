import { ReactNode } from 'react';
import css from './Drawer.module.css';

type DrawerHeaderFooter = {
  children: ReactNode;
  className?: string;
};

export default function DrawerHeader({
  children,
  className,
}: DrawerHeaderFooter) {
  const classes = [css.header, className].filter(Boolean).join(' ');
  return <div className={classes}>{children}</div>;
}
