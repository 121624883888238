import cn from 'classnames';
import { motion } from 'framer-motion';
import { useCallback, useEffect } from 'react';

import { Button } from '@common/Button/Button';
import { IconArrow } from '@common/Icons/Icons';
import { IconClose } from '@common/Icons/Icons';
import { Tooltip } from '@common/Tooltip/Tooltip';
import useModal from '@hooks/useModal';
import transitions from '@utils/transitions';

import styles from './Modal.module.css';
import useDeprecationWarning from '@hooks/useDeprecationWarning';

const DEPRECATION_WARNING =
  'common/Modal and the useModal hook have been deprecated. Use Common/Dialog instead.';

export default function Modal({
  title,
  isPanel,
  hideCloseButton,
  disableEscapeKeyDown,
  onClickNext,
  onClickPrev,
  children,
}) {
  useDeprecationWarning(DEPRECATION_WARNING);
  const { closeModal } = useModal();
  const showNextPrevButtons = onClickNext || onClickPrev;

  const keyboardShortcuts = useCallback(
    (event) => {
      // key: escape
      if (event.keyCode === 27) {
        closeModal();
      }
      if (showNextPrevButtons) {
        // keys: → j
        if (onClickNext && [39, 74].includes(event.keyCode)) {
          onClickNext();
        }
        // keys: ← k
        if (onClickPrev && [37, 75].includes(event.keyCode)) {
          onClickPrev();
        }
      }
    },
    [showNextPrevButtons, onClickNext, onClickPrev, closeModal]
  );

  useEffect(() => {
    document.body.classList.add(styles.overflowHidden);
    if (!hideCloseButton || !disableEscapeKeyDown) {
      document.addEventListener('keydown', keyboardShortcuts);
    }
    return () => {
      document.body.classList.remove(styles.overflowHidden);
      document.removeEventListener('keydown', keyboardShortcuts);
    };
  });

  const variants = {
    hidden: isPanel ? { opacity: 0, x: 32 } : { opacity: 0, y: 16, rotateX: 4 },
    visible: isPanel ? { opacity: 1, x: 0 } : { opacity: 1, y: 0, rotateX: 0 },
  };

  return (
    <motion.div
      className={
        isPanel
          ? cn('p-sm-12', styles.overlayPanel)
          : cn('py-xs-12', 'py-sm-64', styles.overlayModal)
      }
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
      transition={{ duration: 0.25 }}
    >
      <div
        className={styles.backdrop}
        aria-hidden={true}
        onClick={() => !hideCloseButton && closeModal()}
      />
      <motion.div
        className={cn(styles.modal, isPanel ? styles.panel : null)}
        role="dialog"
        aria-labelledby="dialog-label"
        aria-modal={true}
        variants={variants}
        transition={transitions.spring}
      >
        {title && (
          <header className={styles.header}>
            <h3 className="m-0" id="dialog-label">
              {title}
            </h3>
            <div className="d-flex align-items-center">
              {showNextPrevButtons && (
                <div className="d-flex align-items-center mr-4">
                  <Tooltip
                    content={
                      onClickPrev && (
                        <>
                          Previous
                          <kbd className="ml-8 mr-n4">k</kbd>
                        </>
                      )
                    }
                  >
                    <Button
                      type="icon"
                      disabled={!onClickPrev}
                      onClick={onClickPrev}
                      className="mx-4"
                    >
                      <IconArrow direction="left" />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    content={
                      onClickNext && (
                        <>
                          Next
                          <kbd className="ml-8 mr-n4">j</kbd>
                        </>
                      )
                    }
                  >
                    <Button
                      type="icon"
                      disabled={!onClickNext}
                      onClick={onClickNext}
                      className="mx-4"
                    >
                      <IconArrow direction="right" />
                    </Button>
                  </Tooltip>
                </div>
              )}
              {!hideCloseButton && (
                <Tooltip
                  content={
                    <>
                      Close
                      <kbd className="ml-8 mr-n4">esc</kbd>
                    </>
                  }
                >
                  <Button type="icon" onClick={closeModal}>
                    <IconClose />
                  </Button>
                </Tooltip>
              )}
            </div>
          </header>
        )}
        <div className={styles.content} tabIndex={-1}>
          {children}
        </div>
      </motion.div>
    </motion.div>
  );
}
