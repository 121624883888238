import { team } from './teams';

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const billing = (teamUuid) => `${team(teamUuid)}/billing`;

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const billingInvoices = (teamUuid) => `${billing(teamUuid)}/invoices`;

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const billingStripe = (teamUuid) => `${billing(teamUuid)}/stripe`;

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const billingStripeCheckout = (teamUuid) =>
  `${billingStripe(teamUuid)}/checkout`;

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const billingStripePortal = (teamUuid) =>
  `${billingStripe(teamUuid)}/portal`;

/**
 *
 * @param {string} teamUuid
 * @returns {string}
 */
export const billingStripeSession = (teamUuid) =>
  `${billingStripe(teamUuid)}/session`;
