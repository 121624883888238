import { api, handleResponseOptions } from '@services/apiService';

import handleRawApiError from './handleRawApiError';
import { billingStripeCheckout } from '../resources/billing';

/**
 *
 * @param {string} teamUuid
 * @returns
 */
const getStripeCheckoutUrl = handleRawApiError(async (teamUuid) =>
  api
    .get(
      billingStripeCheckout(teamUuid),
      handleResponseOptions('getStripeCheckoutUrl')
    )
    .json()
);

export default getStripeCheckoutUrl;
