import Alert from '@common/Alert/Alert';
import { FormInput } from '@common/FormInput/FormInput';
import { Restricted } from '@components/Restricted';
import { useDeleteAccountMutation } from './queries';
import useModal from '@hooks/useModal';
import { DEV_OR_ADMIN } from '@utils/roles';
import { useState } from 'react';
import toast from 'react-hot-toast';

export default function DeleteWorkflowModal({ workflow }) {
  const { closeModal } = useModal();
  const [value, setValue] = useState('');
  const mutation = useDeleteAccountMutation();

  const confirmed = value === workflow.name;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!confirmed) return;

    await mutation.mutateAsync(workflow.uuid, {
      onError() {
        toast.error('Failed to delete workflow, please try again.');
      },
    });

    closeModal();
  };

  return (
    <>
      <Alert title="This action cannot be undone" severity="error" isModal>
        This workflow will be deleted and my break existing third party
        integrations
      </Alert>
      <form onSubmit={handleSubmit}>
        <FormInput
          autoFocus
          className="mb-20"
          label={
            <>
              Please type <b>{workflow.name}</b> to confirm
            </>
          }
          inputProps={{
            value,
            placeholder: workflow.name,
            onChange: (e) => setValue(e.target.value),
          }}
        />
        <Restricted.Button
          type="submit"
          variant="danger"
          roles={DEV_OR_ADMIN}
          disabled={!confirmed}
          loading={mutation.isLoading}
        >
          Delete Workflow
        </Restricted.Button>
      </form>
    </>
  );
}
