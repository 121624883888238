import { motion } from 'framer-motion';

import { Spinner } from '@common/Spinner/Spinner';

import styles from './Loading.module.css';

export const Loading = (props) => (
  <motion.div className={styles.loading} {...props}>
    <Spinner size="var(--token-32)" />
  </motion.div>
);
