import css from './styles.module.css';
import { motion } from 'framer-motion';
import { AppSwitcher } from './AppSwitcher';
import { TeamSwitcher } from './TeamSwitcher';
import { useCurrentApp, useCurrentTeam, useUserQuery } from '@queries/user';
import SkeletonText from '@common/SkeletonText';
import { useParams } from 'react-router-dom';
import { PCIMode } from './PCIMode';
import { Sandbox } from '../../../Sandbox';

export function Switcher() {
  const { app } = useCurrentApp();
  const { teamUuid } = useParams();
  const { data, isInitialLoading } = useUserQuery();

  if (isInitialLoading) {
    return (
      <div className={css.breadcrumbs}>
        <SkeletonText width={120} height={20} />
        <div className={css.breadcrumbSeparator} />
        <SkeletonText width={60} height={20} />
      </div>
    );
  }

  if (!teamUuid) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={css.breadcrumbs}
    >
      <TeamSwitcher user={data} />
      {app && (
        <>
          <div className={css.breadcrumbSeparator} />
          <AppSwitcher user={data} />
          {app.pciMode && <PCIMode />}
          {app.sandbox && <Sandbox.Indicator />}
        </>
      )}
    </motion.div>
  );
}
