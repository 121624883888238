import { cn } from '@vault/utilities';
import css from './styles.module.css';
import { ComponentPropsWithoutRef, ElementType } from 'react';
import { TextHeading } from './TextHeading';

export type TextProps<T extends ElementType> = ComponentPropsWithoutRef<T> & {
  as?: T;
  weight?: number;
  color?: string;
  leading?: 'sm' | 'md' | 'lg';
  size?: 'sm' | 'md' | 'lg';
};

type TextCSSProperties = React.CSSProperties & {
  '--weight'?: number;
  '--color'?: string;
};

export function Text<T extends ElementType = 'p'>({
  as,
  children,
  weight,
  color,
  size = 'md',
  leading,
  className,
  ...props
}: TextProps<T>) {
  const classes = cn(css.text, className);
  const Component = as ?? 'p';

  let styles: TextCSSProperties = {};
  styles['--weight'] = weight;
  styles['--color'] = color;

  return (
    <Component
      className={classes}
      data-size={size}
      data-leading={leading}
      style={styles}
      {...props}
    >
      {children}
    </Component>
  );
}

Text.Heading = TextHeading;
