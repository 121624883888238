import { useRef, useState, useCallback } from 'react';

type UseCopyToClipboardReturn = {
  copy: (content: string) => void;
  copied: boolean;
};

export default function useCopyToClipboard(): UseCopyToClipboardReturn {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [copied, setCopied] = useState(false);

  const copy = useCallback((content: string) => {
    if (timer.current) clearTimeout(timer.current);
    navigator.clipboard.writeText(content.trimEnd());
    setCopied(true);
    timer.current = setTimeout(() => setCopied(false), 2000);
  }, []);

  return { copy, copied };
}
