import { app } from './apps';

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const hubspot = (teamUuid, appUuid) =>
  `${app(teamUuid, appUuid)}/hubspot/teams`;
