import { api, handleResponseOptions } from '@services/apiService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  apps as appsResource,
  app as appResource,
} from '@utils/resources/apps';
import { adminAppPciMode } from '@utils/resources/admin';
import { teamQuery } from './team';
import { useCallback } from 'react';
import { USER_QUERY_KEY } from './user';

// Scope Types

/**
 * @type {'scope/App__list'}
 */
export const APP_LIST_SCOPE_TYPE = '__scope::App__list';

// Query Definitions
export const appsQuery = (teamUuid) => ({
  queryKey: [{ scope: APP_LIST_SCOPE_TYPE, teamUuid }],
  queryFn: async () =>
    api.get(appsResource(teamUuid), handleResponseOptions('appsQuery')).json(),
});

// Query Selectors
export const selectApps = (data) => data.apps;

export const selectAppNames = (data) =>
  data.apps.map((app) => app.name.toUpperCase());

// Query Hooks
export const useAppsQuery = (teamUuid, params) => {
  const queryClient = useQueryClient();

  return useQuery({
    ...appsQuery(teamUuid),
    placeholderData: () => {
      const teamData = queryClient.getQueryData(teamQuery(teamUuid).queryKey);

      return {
        apps: teamData?.team?.apps ?? [],
      };
    },
    select: params?.select ?? selectApps,
  });
};

export const useAppQuery = (teamUuid, appUuid, args = {}) => {
  const selectApp = useCallback(
    (data) => data.apps?.find((app) => app.uuid === appUuid) ?? null,
    [appUuid]
  );

  return useQuery({ ...appsQuery(teamUuid), select: selectApp, ...args });
};

// Mutation definitions
const createAppMutation = (teamUuid, { onSettled }) => ({
  mutationFn: async (params) =>
    api
      .post(appsResource(teamUuid), {
        ...handleResponseOptions('createAppMutation'),
        json: params,
      })
      .json(),
  onSettled,
});

const updateAppMutation = (teamUuid, appUuid, { onSettled }) => ({
  mutationFn: async (params) =>
    api
      .put(appResource(teamUuid, appUuid), {
        ...handleResponseOptions('updateAppMutation'),
        json: params,
      })
      .json(),
  onSettled,
});

const updateAppPciModeMutation = (teamUuid, appUuid, { onSettled }) => ({
  mutationFn: async (pciMode) =>
    api
      .put(adminAppPciMode(teamUuid, appUuid), {
        ...handleResponseOptions('setAppPciModeMutation'),
        json: { pciMode },
      })
      .json(),
  onSettled,
});

const deleteAppMutation = (teamUuid, appUuid, { onSettled, onSuccess }) => ({
  mutationFn: () =>
    api
      .delete(
        appResource(teamUuid, appUuid),
        handleResponseOptions('deleteAppMutation')
      )
      .json(),
  onSettled,
});

// Mutation hooks
export function useCreateAppMutation(teamUuid) {
  const queryClient = useQueryClient();

  const onSettled = async (response) => {
    const app = response?.app;
    if (!app) return;

    await queryClient.invalidateQueries({
      queryKey: appsQuery(teamUuid).queryKey,
    });

    const data = queryClient.getQueryData(USER_QUERY_KEY);
    if (data) {
      queryClient.setQueryData(USER_QUERY_KEY, {
        ...data,
        user: {
          ...data.user,
          teams: data.user.teams.map((team) => {
            if (team.uuid === teamUuid) {
              return {
                ...team,
                apps: [...(team.apps || []), app],
              };
            }

            return team;
          }),
        },
      });
    }
  };

  return useMutation(createAppMutation(teamUuid, { onSettled }));
}

export function useUpdateAppMutation(teamUuid, appUuid) {
  const queryClient = useQueryClient();

  const onSettled = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [{ teamUuid }] }),
      queryClient.invalidateQueries({ queryKey: USER_QUERY_KEY }),
    ]);
  };

  return useMutation(updateAppMutation(teamUuid, appUuid, { onSettled }));
}

export function useUpdateAppPciModeMutation(teamUuid, appUuid) {
  const queryClient = useQueryClient();

  const onSettled = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [{ teamUuid }] }),
      queryClient.invalidateQueries({ queryKey: USER_QUERY_KEY }),
    ]);
  };

  return useMutation(
    updateAppPciModeMutation(teamUuid, appUuid, { onSettled })
  );
}

export function useDeleteAppMutation(teamUuid, appUuid) {
  const queryClient = useQueryClient();

  const onSettled = async () => {
    await Promise.all([
      queryClient.resetQueries({ queryKey: [{ teamUuid }] }),
      queryClient.invalidateQueries({ queryKey: USER_QUERY_KEY }),
    ]);
  };

  return useMutation(deleteAppMutation(teamUuid, appUuid, { onSettled }));
}
