import tinycolor from 'tinycolor2';

export function avatar(username: string) {
  function fnv32a(str: string) {
    let hash = 2166136261;
    for (let i = 0; i < str.length; i++) {
      hash ^= str.charCodeAt(i);
      hash *= 16777619;
    }
    return hash;
  }

  const hash = fnv32a(username);
  const c1 = tinycolor({ h: hash % 360, s: 0.95, l: 0.5 });
  const second = c1.triad()[1].toHexString();

  const svg = `
    <svg width="120" height="120" viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stop-color="${c1.toHexString()}" />
          <stop offset="100%" stop-color="${second}" />
        </linearGradient>
      </defs>
      <rect fill="url(#gradient)" x="0" y="0" width="120" height="120" />
    </svg>
  `;

  const blob = new Blob([svg], { type: 'image/svg+xml' });
  const dataURL = URL.createObjectURL(blob);

  return dataURL;
}
