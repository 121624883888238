import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';
import css from './styles.module.css';
import connectFieldsStyles from '../ConnectedFields/styles.module.css';
import { useFormField } from '@common/FormField';

export type InputProps = Omit<
  ComponentPropsWithoutRef<'input'>,
  'size' | 'prefix'
> & {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  error?: boolean;
  width?: 'auto';
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    className,
    size = 'md',
    error,
    onBlur,
    onFocus,
    prefix,
    suffix,
    width,
    ...props
  },
  ref
) {
  const formField = useFormField();
  const [focused, setFocused] = useState(false);
  const classes = [connectFieldsStyles.connectableField, css.input, className]
    .filter(Boolean)
    .join(' ');

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const container = e.target as HTMLDivElement;
    container.querySelector('input')?.focus();
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) onBlur(e);
  };

  return (
    <div
      className={classes}
      data-focused={focused}
      data-size={size}
      data-error={error ?? Boolean(formField?.error)}
      data-width={width}
      onClick={handleClick}
      data-disabled={props.disabled}
    >
      {prefix && <span className={css.prefix}>{prefix}</span>}
      <input
        id={formField?.id ?? props.id}
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      {suffix && <span className={css.suffix}>{suffix}</span>}
    </div>
  );
});
